import ArretesService from "@/services/http/arretes.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchArretes({ rootState }, data) {
      const res = await ArretesService.fetchArretes(data);
      return res;
    },
    async searchArretes({ rootState }, data) {
      const res = await ArretesService.searchArretes(data);
      return res;
    },
    async addArrete({ rootState }, data) {
      const res = await ArretesService.addArrete(data);
      return res;
    },
    async getArrete({ rootState }, data) {
      const res = await ArretesService.getArrete(data);
      return res;
    },
    async getArreteDownloadArrete({ rootState }, data) {
      const res = await ArretesService.getArreteDownloadArrete(data);
      return res;
    },
    async updateArrete({ rootState }, data) {
      const res = await ArretesService.updateArrete(data);
      return res;
    },
  },
};
