import { BaseService } from "./base.service";

export class ArreteService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  fetchArretes(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get("/arretes", { params: data })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  searchArretes(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/arretes/search", data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  addArrete(userData) {
    return new Promise((resolve, reject) => {
      this.axiosInstance  
        .post("/arretes", userData)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
  getArrete(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/arretes/${id}`, {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
  getArreteDownloadArrete(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/arretes/${id}/download-arrete`, {}, {responseType: 'blob'})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateArrete({ id, data }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/arretes/${id}`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  // fetchAgentsEtab(data) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosInstance
  //       .get("/inscriptions/list-agents", { params: data })
  //       .then((response) => resolve(response))
  //       .catch((error) => reject(error));
  //   });
  // }

  // fetchCommissariats(data) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosInstance
  //       .get("/admins/list-commissariats", { params: data })
  //       .then((response) => resolve(response))
  //       .catch((error) => reject(error));
  //   });
  // }

  // fetchAgentsPolice(data) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosInstance
  //       .get("/admins/list-agents-police", { params: data })
  //       .then((response) => resolve(response))
  //       .catch((error) => reject(error));
  //   });
  // }

  // fetchUser({ id }) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosInstance
  //       .get(`/api/v1/users/${id}`)
  //       .then((response) => resolve(response))
  //       .catch((error) => reject(error));
  //   });
  // }

  // updateUserFormData({ id, data }) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosInstance
  //       .post(`/users/${id}?_method=PUT`, data)
  //       .then((response) => resolve(response))
  //       .catch((error) => reject(error));
  //   });
  // }

  // resetPasswordUser(userData) {
  //   console.log("--- userData: --- ", userData);
  //   return new Promise((resolve, reject) => {
  //     this.axiosInstance
  //       .post("/forgot-password", userData)
  //       .then((response) => resolve(response))
  //       .catch((error) => reject(error));
  //   });
  // }

  // archiveUser(data) {
  //   const { id } = data;
  //   return new Promise((resolve, reject) => {
  //     this.axiosInstance
  //       .delete(`/users/${id}`, {})
  //       .then((response) => resolve(response))
  //       .catch((error) => reject(error));
  //   });
  // }

  // getUser(id) {
  //   return new Promise((resolve, reject) => {
  //     this.axiosInstance
  //       .get(`/users/${id}`, {})
  //       .then((response) => resolve(response))
  //       .catch((error) => reject(error));
  //   });
  // }
}

export default new ArreteService();
