const statuses = [
  {
    status: 'deliberation_session_check',
    etape: 'EN ATTENTE DE DÉLIBÉRATION',
    action_label: 'ENREGISTRER LA DÉLIBÉRATION',
    next_step: 'transfert_pv_deliberation_to_minister',
    class: 'relief-secondary',
  },
  {
    status: 'transfert_pv_deliberation_to_minister',
    etape: 'TRANSFERT DES RÉSULTATS DE LA DÉLIBÉRATION AU MINISTRE',
    action_label: 'TRANSFÉRER LE PV DE DÉLIBÉRATION AU MINISTRE',
    class: 'relief-secondary',
    duration: 5,
    // next_decisions_options_count: 3,
    treatment_section: true,
    next_step: 'minister_decisions',
  },
  {
    status: 'minister_decisions',
    etape: 'DÉCISIONS FINALES DU MINISTRE',
    action_label: "AUTORISATION DE LA TRASMISSION DU PROJET D'ARRÊTÉ À LA CELLULE D'ANALYSE",
    class: 'relief-primary',
    duration: 10,
    treatment_section: true,
    is_minister_validation: true,
    ministerial_decree_step: true,
    no_sync_with_requests: true,
    next_step: 'acknowledgement_of_receipt_minister_decisions',
  },
  { // accusé reception décisions du ministre
    status: 'acknowledgement_of_receipt_minister_decisions',
    etape: 'ACCUSÉ DE RÉCEPTION DES DÉCISIONS DU MINISTRE',
    action_label: 'ACCUSER RÉCEPTION DES DÉCISIONS DU MINISTRE',
    class: 'relief-secondary',
    ministerial_decree_step: true,
    set_ministerial_decree_number: true,
    next_step: 'user_notification',
  },
  { // accusé reception décisions du ministre
    status: 'user_notification',
    etape: "NOTIFICATION DES DÉCISIONS AUX REQUÉRANTS",
    action_label: "NOTIFIER AUX REQUÉRANTS LES DÉCISIONS POUR L'ENSEMBLE DES DEMANDES DE LA COMMISSION",
    class: 'relief-secondary',
    ministerial_decree_step: true,
    final_state: true,
  },
]
export default statuses
