<template>
  <div>
    <validation-observer ref="formRules">
      <!-- head -->
      <div>
        <b-card>
          <b-card-header class="nopadding">
            <b-row
              no-gutters
              class="w-100 mb-0"
            >
              <!-- <b-col md="1">
                <div class="d-flex justify-content-between">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    @click="
                      $router.push({
                        name: 'arretes-list',
                        params: {},
                      })
                    "
                  >
                    <i class="las la-arrow-left" />
                  </b-button>
                </div>
              </b-col> -->
              <b-col
                md="12"
                class="d-flex align-items-center justify-content-center"
              >
                <div class="w-100">
                  <h1 class="m-auto text-center">
                    Deliberation
                  </h1>
                  <!-- <h4 class="m-auto text-center">
                    Hôtels et établissements assimilés
                  </h4> -->
                  <hr class="hr-primary">
                  <span class="row">
                    <span class="col-3">
                      Date de création de la commission: <br>
                      <strong>{{ curr_arrete.created_at }}</strong></span>
                    <span class="col-3">
                      Date de prévue de la commission: <br>
                      <strong>{{ curr_arrete.predicted_date }}</strong></span>
                    <span class="col-3">
                      Date de tenue de la séance: <br>
                      <strong>{{ curr_arrete.meeting_date }}</strong></span>
                    <span class="col-3">
                      Auteur de la commission: <br>
                      <strong>{{
                        curr_arrete.created_by &&
                          curr_arrete.created_by.fullname
                      }}</strong></span>

                    <span class="col-12 mt-1">
                      Présence: <br>
                      <span
                        v-if="
                          curr_arrete &&
                            curr_arrete.participants_ids &&
                            curr_arrete.participants_ids.length > 0
                        "
                      >
                        Participants internes à l'administration :
                        <strong
                          v-for="(
                            participant, participant_index
                          ) in curr_arrete.participants_ids"
                        >
                          {{
                            get_user_by_id(participant) &&
                              get_user_by_id(participant).fullname
                          }}
                          -
                          {{
                            get_user_by_id(participant) &&
                              get_user_by_id(participant).roles[0].title
                          }},
                        </strong>
                      </span>
                      <br>
                      <span
                        v-if="
                          curr_arrete &&
                            curr_arrete.participants_string &&
                            curr_arrete.participants_string.length > 0
                        "
                      >
                        Participants externes à l'administration :
                        <strong
                          v-for="(
                            participant, participant_index
                          ) in curr_arrete.participants_string"
                        >
                          {{ participant }},
                        </strong>
                      </span>
                    </span>
                  </span>
                </div>
                <br>
              </b-col>
            </b-row>
          </b-card-header>
          <content-loader
            v-if="loading"
            view-box="0 0 400 460"
            :speed="2"
            primary-color="#f3f3f3"
            secondary-color="#ecebeb"
          >
            <circle
              cx="29"
              cy="30"
              r="17"
            />
            <rect
              x="58"
              y="18"
              rx="2"
              ry="2"
              width="140"
              height="10"
            />
            <rect
              x="58"
              y="34"
              rx="2"
              ry="2"
              width="140"
              height="10"
            />
            <rect
              x="-7"
              y="60"
              rx="2"
              ry="2"
              width="408"
              height="132"
            />
          </content-loader>
        </b-card>
      </div>

      <!-- statues -->
      Statuts de l'arrêté :
      <div
        v-if="
          curr_arrete && curr_arrete.statuses && curr_arrete.statuses.length > 0
        "
        class="col-md-12 horizontal-scroll nopadding"
      >
        <span
          v-for="(status, status_key) in curr_arrete.statuses"
          :key="status_key"
          class=""
        >
          <span v-if="status_key > 0">----</span>
          <b-badge :variant="variant_for_timeline(status.name)">
            <span class="d-flex flex-row align-items-center">
              <span :class="status.reason ? 'col-11' : 'col-12'">
                {{ statusMatching(states, status.name) }} <br>
                <small style="">
                  {{ status.created_at }}
                  {{
                    status.user &&
                      ' par ' + status.user.lastname + ' ' + status.user.firstname
                  }}
                  {{ status.reason && ', C' }}
                </small>
              </span>
              <span
                v-if="status.reason"
                class="col-1 d-flex align-item-center"
              >
                <i
                  v-b-tooltip.hover.left="$t(status.reason && status.reason)"
                  class="las la-pencil-alt iconMedium"
                />
              </span>
            </span>
          </b-badge>
        </span>
      </div>
      <!-- / statues end -->

      <!-- content -->
      <div
        v-if="!isFetchingFormDetails"
        :class="show_vizualisation_section ? 'main-grid-file-display' : 'main-grid'
        "
        class="mt-1"
      >
        <div
          cols="10"
          xl="10"
          md="10"
          class="position-relative w-100"
        >
          <b-row
            v-if="!loading"
            class="w-100"
          >
            <!-- body -->
            <b-col
              lg="12"
              class="pb-2"
            >
              <!-- RENSEIGNEMENTS GENERAUX  -->
              <div>
                <b-card id="renseignementsgeneraux">
                  <b-card-header class="d-flex justify-content-between nopadding">
                    <h3>Demandes en cours d'examen</h3>
                  </b-card-header>

                  <hr class="hr-primary">

                  <b-card-body class="row">
                    <!-- UEH -->
                    <span
                      v-if="requests_by_sector_counts.ueh > 0"
                      class="h3 col-12"
                    >
                      Etablissements d'hébergement touristique
                    </span>
                    <span
                      v-for="(demande, index_demande) in curr_arrete.demandes"
                      v-if="
                        requests_by_sector_counts.ueh > 0 &&
                          demande.selectedForm.sector == 'UEH'
                      "
                      :key="demande.id"
                      class="col-6"
                    >
                      <div class="p-1 demandes">
                        <div>
                          <span>
                            <h6>
                              <strong>{{ $t('Application_submission_date') }}:</strong>
                              {{ demande.created_at }}
                            </h6>
                            <h6>
                              <strong>{{ $t('demand_detail.reference') }} :</strong>
                              <span
                                class="link"
                                @click="
                                  $router.push({
                                    name: 'formulaires-details',
                                    params: {
                                      id: demande.id,
                                      slug: demande.slug,
                                    },
                                  })
                                "
                              >{{ demande.reference }}</span>
                            </h6>
                            <h6
                              class="mt-2"
                              for="type"
                              @click.stop=""
                            >
                              <strong>{{ $t('Type_establishment_proposed_in_committee') }}
                                :</strong>
                              <v-select
                                id="type"
                                v-model="demande.type_etablissement"
                                label="title"
                                :reduce="(option) => option.title"
                                :options="options_type_secteur_hebergement"
                                class="select-size-lg"
                                @input="
                                  update_request(
                                    demande.id,
                                    get_resquest_index(demande.id),
                                    {
                                      type_etablissement:
                                        demande.type_etablissement,
                                    }
                                  )
                                "
                              />
                            </h6>
                            <h6>
                              <strong>{{ $t('table_columns.requests_types') }} :</strong>
                              {{
                                $t(getTypeDemandebyCode(demande.data.code_demande))
                              }}
                            </h6>
                            <h6>
                              <strong>{{ $t('demand_detail.name_establishment') }} :</strong>
                              {{
                                demande.data.raison_sociale !== null
                                  ? demande.data.raison_sociale
                                  : '--'
                              }}
                            </h6>
                            <h6>
                              <strong>{{ $t('exploitant') }}:</strong>
                              {{ demande.data.nom_promoteur }}
                              {{ demande.data.prenom_promoteur }}
                            </h6>
                          </span>

                          <h6 @click.stop="">
                            <strong>{{ $t('Recharacterization_request_expressed_commission') }}</strong>
                            <v-select
                              id="type"
                              v-model="demande.classement"
                              label="title"
                              :reduce="(option) => option.value"
                              :options="options_classement"
                              class="select-size-lg"
                              @input="
                                update_request(
                                  demande.id,
                                  get_resquest_index(demande.id),
                                  { classement: demande.classement }
                                )
                              "
                            />
                          </h6>
                          <span @click.stop="">
                            <!-- showing visits controls results ---------------------------------------------->
                            <!-- autorisation control -->
                            <span
                              v-if="demande.have_autorisation_visit"
                              class="row mt-2"
                            >
                              <span class="col-5"><strong>Visite technique réalisée par l'équipe de :</strong></span>
                              <span class="col-7">
                                <hr class="hr-primary">
                              </span>
                              <span
                                v-if="demande.technical_visitor_id"
                                class="col-12"
                              >
                                {{
                                  get_user_by_id(
                                    demande.technical_visitor_id
                                  ) &&
                                    get_user_by_id(demande.technical_visitor_id)
                                      .fullname
                                }}
                                <br>
                                <!-- <strong>Date du RDV: </strong> date -->
                              </span>
                            </span>
                            <!-- classification control -->
                            <span
                              v-if="demande.have_classification_visit"
                              class="row mt-1"
                            >
                              <span class="col-5"><strong>Visite de classement</strong></span>
                              <span class="col-7">
                                <hr class="hr-primary">
                              </span>
                              <span
                                v-if="demande.classification_visitor_id"
                                class="col-12"
                              >
                                <strong>Responsable de la visite: </strong>
                                {{
                                  get_user_by_id(
                                    demande.classification_visitor_id
                                  ) &&
                                    get_user_by_id(
                                      demande.classification_visitor_id
                                    ).fullname
                                }}
                                <br>
                                <!-- <strong>Date du RDV: </strong> date -->
                              </span>
                            </span>
                            <!-- audit control -->
                            <span
                              v-if="demande.have_exam_visit"
                              class="row mt-1"
                            >
                              <span class="col-5"><strong>Audit mystère</strong></span>
                              <span class="col-7">
                                <hr class="hr-primary">
                              </span>
                              <span
                                v-if="demande.auditor_id"
                                class="col-12"
                              >
                                <strong>Responsable visite: </strong>{{
                                  get_user_by_id(demande.auditor_id) &&
                                    get_user_by_id(demande.auditor_id).fullname
                                }}
                                <br>
                                <!-- <strong>Date du RDV: </strong> date -->
                              </span>
                            </span>
                            <!-- showing decisions and choises control ---------------------------------------------------->
                            <span class="mt-2">
                              <!-- ot decisions classic -->
                              <span v-if="request_have_ot_decisions(demande, 'all')">
                                <!-- for classic request -->
                                <span class="w-100">
                                  <hr class="hr-primary">
                                  <!-- 📌  ot : decision autorisation -->
                                  📌 {{ get_specific_nodes(demande.statuses, 'name', 'deliberation_session_check', 1) }}
                                  <span class="d-flex justify-content-between">
                                    <span>
                                      <strong>
                                        {{
                                          get_current_step(
                                            demande.process_steps,
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'deliberation_session_check',
                                              1
                                            ).name
                                          ).etape
                                        }}
                                      </strong>
                                      <span
                                        v-if="
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'deliberation_session_check',
                                            1
                                          ).reason
                                        "
                                      >
                                        <br>
                                        <i>Commentaire :</i>
                                        {{
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'deliberation_session_check',
                                            1
                                          ).reason
                                        }}
                                      </span>
                                    </span>
                                    <span>
                                      <b-badge
                                        pill
                                        :variant="get_request_color(
                                          demande,
                                          'deliberation_session_check'
                                        )
                                        "
                                      >-</b-badge>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <!-- minister decisions classic -->
                              <span
                                v-if="
                                  request_have_minister_decisions(
                                    demande,
                                    'all'
                                  )
                                "
                              >
                                <!-- for classic request -->
                                <span class="w-100">
                                  <hr class="hr-primary">
                                  <!-- 📌 minister : decision autorisation -->
                                  <span class="d-flex justify-content-between">
                                    <span>
                                      <strong>
                                        {{
                                          get_current_step(
                                            demande.process_steps,
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'transfert_pv_deliberation_to_minister',
                                              1
                                            ).name
                                          ).etape
                                        }}
                                      </strong>
                                      <span
                                        v-if="
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'transfert_pv_deliberation_to_minister',
                                            1
                                          ).reason
                                        "
                                      >
                                        <br>
                                        <i>Commentaire :</i>
                                        {{
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'transfert_pv_deliberation_to_minister',
                                            1
                                          ).reason
                                        }}
                                      </span>
                                    </span>
                                    <span>
                                      <b-badge
                                        pill
                                        :variant="get_request_color(
                                          demande,
                                          'transfert_pv_deliberation_to_minister'
                                        )
                                        "
                                      >-</b-badge>
                                    </span>
                                  </span>
                                </span>
                              </span>

                              <!-- ot decisions classification -->
                              <span v-if="request_have_ot_decisions(demande, 'all')">
                                <!-- for request autorisation d'exploitation et premier classement (show classement) -->
                                <span
                                  v-if="
                                    in_audit_process(
                                      get_resquest_index(demande.id)
                                    ) == true &&
                                      request_have_ot_decisions(demande)
                                  "
                                  class="w-100"
                                >
                                  <hr class="hr-primary">
                                  <!-- 📌 ot : decision classification : {{ get_specific_nodes(demande.statuses, 'name', 'classification_deliberation_session_check', 1).name }} -->
                                  <span class="d-flex justify-content-between">
                                    <span>
                                      <strong>
                                        {{
                                          get_current_step(
                                            demande.process_steps,
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'classification_deliberation_session_check',
                                              1
                                            ).name
                                          ).etape
                                        }}
                                      </strong>
                                      <span
                                        v-if="
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'classification_deliberation_session_check',
                                            1
                                          ).reason
                                        "
                                      >
                                        <br>
                                        <i>Commentaire :</i>
                                        {{
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'classification_deliberation_session_check',
                                            1
                                          ).reason
                                        }}
                                      </span>
                                    </span>
                                    <span>
                                      <b-badge
                                        pill
                                        :variant="get_request_color(
                                          demande,
                                          'classification_deliberation_session_check'
                                        )
                                        "
                                      >-</b-badge>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <!-- minister decisions classification -->
                              <span
                                v-if="
                                  request_have_minister_decisions(
                                    demande,
                                    'all'
                                  )
                                "
                              >
                                <!-- for request autorisation d'exploitation et premier classement (show classement) -->
                                <span
                                  v-if="
                                    in_audit_process(
                                      get_resquest_index(demande.id)
                                    ) == true &&
                                      request_have_minister_decisions(demande)
                                  "
                                  class="w-100"
                                >
                                  <hr class="hr-primary">
                                  <!-- 📌 minister : decision classification : {{ request_have_minister_decisions(demande) }} -->
                                  <span
                                    v-if="
                                      request_have_minister_decisions(
                                        demande
                                      ) &&
                                        in_audit_process(
                                          get_resquest_index(demande.id)
                                        ) == true
                                    "
                                    class="w-100"
                                  >
                                    <span class="d-flex justify-content-between">
                                      <span>
                                        <strong>
                                          {{
                                            get_current_step(
                                              demande.process_steps,
                                              get_specific_nodes(
                                                demande.statuses,
                                                'name',
                                                'transfert_all_documentation_to_minister',
                                                1
                                              ).name
                                            ).etape
                                          }}
                                        </strong>
                                        <span
                                          v-if="
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'transfert_all_documentation_to_minister',
                                              1
                                            ).reason
                                          "
                                        >
                                          <br>
                                          <i>Commentaire :</i>
                                          {{
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'transfert_all_documentation_to_minister',
                                              1
                                            ).reason
                                          }}
                                        </span>
                                      </span>
                                      <span>
                                        <b-badge
                                          pill
                                          :variant="get_request_color(
                                            demande,
                                            'transfert_all_documentation_to_minister'
                                          )
                                          "
                                        >-</b-badge>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>

                              <!-- 💊 demande actions 💊 -->
                              <span
                                v-if="
                                  (!request_have_ot_decisions(demande) ||
                                    !request_have_minister_decisions(
                                      demande
                                    )) &&
                                    demande.next_node &&
                                    demande.next_node.length > 0
                                "
                              >
                                <hr class="hr-primary">
                                <!-- 📌 decisions actions  -->
                                <!-- decisions for OT or Minister -->
                                <span
                                  v-if="
                                    !demande.next_node
                                      .hide_on_ministerial_decree_step &&
                                      demande.next_node.length !== 0
                                  "
                                >
                                  <!-- v-b-tooltip.hover.top.v-warning="(!isMinister &&
                                      decision.is_minister_validation &&
                                      'Le ministre doit valider cette étape.') ||
                                      (!isAdmin &&
                                        !isMinister &&
                                        !isMtcaAdmin &&
                                        !isOtAdmin &&
                                        'Un administrateur du MTCA ou de l\'Organe Technique doit valider cette étape.')
                                    " -->
                                  <span
                                    v-for="(
                                      decision, index_decision
                                    ) in demande.next_node"
                                    :keys="index_decision"
                                    class="nopadding"
                                  >
                                    <b-button
                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                      :variant="decision.class"
                                      class="my-1"
                                      block
                                      size="sm"
                                      :disabled="(!isMinister &&
                                        decision.is_minister_validation) ||
                                        (!isAdmin &&
                                          !isMtcaAdmin &&
                                          !isOtAdmin &&
                                          !isMinister)
                                      "
                                      @click="
                                        update_status_request(
                                          decision,
                                          demande.id,
                                          get_resquest_index(demande.id)
                                        )
                                      "
                                    >
                                      <b-spinner
                                        v-if="isLoading_update_state"
                                        small
                                      />
                                      <span v-if="!isLoading_update_state">
                                        {{ decision.action_label }}
                                      </span>
                                    </b-button>
                                  </span>
                                </span>
                                <!-- comment -->
                                <b-form-textarea
                                  v-if="
                                    !demande.next_node
                                      .hide_on_ministerial_decree_step
                                  "
                                  v-model="next_step_comment_request[
                                    get_resquest_index(demande.id)
                                  ]
                                  "
                                  placeholder="Commentaire"
                                  rows="1"
                                  class=""
                                />
                              </span>
                            </span>
                            <!-- acknowledgement_of_receipt_minister_decisions -->
                            <span
                              v-if="
                                demande.statuses[0].name ===
                                  'acknowledgement_of_receipt_minister_decisions'
                              "
                              v-b-tooltip.hover.top.v-warning="!isAdmin &&
                                !isMinister &&
                                !isMtcaAdmin &&
                                !isOtAdmin &&
                                'Un administrateur du MTCA ou de l\'Organe Technique doit valider cette étape.'
                              "
                            >
                              <hr class="hr-primary">
                              <!-- demande.next_node :: {{ demande.next_node }} -->
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                :variant="demande.next_node.class"
                                class="mb-75 mb-1"
                                :disabled="!isAdmin && !isMtcaAdmin && !isOtAdmin
                                "
                                size="sm"
                                block
                                @click="
                                  update_status_request(
                                    demande.next_node,
                                    demande.id,
                                    get_resquest_index(demande.id)
                                  )
                                "
                              >
                                <span>
                                  <span v-if="!isLoading_update_state">
                                    {{ demande.next_node.action_label }}
                                  </span>
                                  <b-spinner
                                    v-else
                                    small
                                  />
                                </span>
                              </b-button>
                            </span>
                          </span>
                        </div>
                      </div>
                    </span>

                    <!-- UEAV -->
                    <span
                      v-if="requests_by_sector_counts.ueav > 0"
                      class="h3 col-12 mt-2"
                    >
                      Agences de voyage
                    </span>
                    <span
                      v-for="(demande, index_demande) in curr_arrete.demandes"
                      v-if="
                        requests_by_sector_counts.ueav > 0 &&
                          demande.selectedForm.sector == 'UEAV'
                      "
                      :key="demande.id"
                      class="col-6"
                    >
                      <div class="p-1 demandes">
                        <div>
                          <span
                            @click="
                              $router.push({
                                name: 'formulaires-details',
                                params: {
                                  id: demande.id,
                                  slug: demande.slug,
                                },
                              })
                            "
                          >
                            <h6>
                              <strong>Date de soummission de la demande :</strong>
                              {{ demande.created_at }}
                            </h6>
                            <h6>
                              <strong>Référence :</strong>
                              {{ demande.reference }}
                            </h6>
                            <h6>
                              <strong>Etablissement :</strong>
                              {{
                                demande.data.raison_sociale !== null
                                  ? demande.data.raison_sociale
                                  : '--'
                              }}
                            </h6>
                            <h6>
                              <strong>Promoteur: </strong>
                              {{ demande.data.nom_promoteur }}
                              {{ demande.data.prenom_promoteur }},
                            </h6>
                            <h6>
                              <strong>Gérant: </strong>
                              {{ demande.user.profile.nom_representant }}
                              {{ demande.user.profile.prenoms_representant }},
                              {{ demande.user.profile.sexe_representant }} -
                              {{
                                demande.user.profile.nationalite_representant
                              }},
                            </h6>
                            <h6>
                              <strong>Type de demande :</strong>
                              {{
                                getTypeDemandebyCode(demande.data.code_demande)
                              }}
                            </h6>
                          </span>

                          <!-- showing visits controls results ---------------------------------------------->
                          <!-- have_metting_with_applicant -->
                          <span
                            v-if="demande.have_metting_with_applicant"
                            class="row mt-1"
                          >
                            <span class="col-5"><strong>Entretien avec le requérant</strong></span>
                            <span class="col-7">
                              <hr class="hr-primary">
                            </span>
                            <span
                              v-if="demande.applicant_responsable_id"
                              class="col-12"
                            >
                              <strong>Responsable visite: </strong>{{
                                get_user_by_id(
                                  demande.applicant_responsable_id
                                ) &&
                                  get_user_by_id(demande.applicant_responsable_id)
                                    .fullname
                              }}
                              <br>
                              <!-- <strong>Date du RDV: </strong> date -->
                            </span>
                          </span>
                          <!-- have_morality_investigation -->
                          <!-- <span v-if="demande.have_morality_investigation" class="row mt-1" >
                            <span class="col-5"><strong>Enquête de moralité</strong></span>
                            <span class="col-7"> <hr class="hr-primary"> </span>
                            <span class="col-12" v-if="demande.morality_investigation_responsable_id">
                              <strong>Responsable visite: </strong>{{ get_user_by_id(demande.morality_investigation_responsable_id) && get_user_by_id(demande.morality_investigation_responsable_id).fullname }} <br>
                              <strong>Date du RDV: </strong> date
                            </span>
                          </span> -->

                          <!-- showing visits controls results ---------------------------------------------->
                          <span>
                            <!-- ot decisions classic -->
                            <span v-if="request_have_ot_decisions(demande, 'all')">
                              <!-- for classic request -->
                              <span class="w-100">
                                <hr class="hr-primary">
                                <!-- 📌  ot : decision autorisation -->
                                <!-- 📌 {{ get_specific_nodes(demande.statuses, 'name', 'deliberation_session_check', 1) }} -->
                                <span class="d-flex justify-content-between">
                                  <span>
                                    <strong>
                                      {{
                                        get_current_step(
                                          demande.process_steps,
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'deliberation_session_check',
                                            1
                                          ).name
                                        ).etape
                                      }}
                                    </strong>
                                    <span
                                      v-if="
                                        get_specific_nodes(
                                          demande.statuses,
                                          'name',
                                          'deliberation_session_check',
                                          1
                                        ).reason
                                      "
                                    >
                                      <br>
                                      <i>Commentaire :</i>
                                      {{
                                        get_specific_nodes(
                                          demande.statuses,
                                          'name',
                                          'deliberation_session_check',
                                          1
                                        ).reason
                                      }}
                                    </span>
                                  </span>
                                  <span>
                                    <b-badge
                                      pill
                                      :variant="get_request_color(
                                        demande,
                                        'deliberation_session_check'
                                      )
                                      "
                                    >-</b-badge>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <!-- minister decisions classic -->
                            <span
                              v-if="
                                request_have_minister_decisions(demande, 'all')
                              "
                            >
                              <!-- for classic request -->
                              <span class="w-100">
                                <hr class="hr-primary">
                                <!-- 📌 minister : decision autorisation -->
                                <span class="d-flex justify-content-between">
                                  <span>
                                    <strong>
                                      {{
                                        get_current_step(
                                          demande.process_steps,
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'transfert_pv_deliberation_to_minister',
                                            1
                                          ).name
                                        ).etape
                                      }}
                                    </strong>
                                    <span
                                      v-if="
                                        get_specific_nodes(
                                          demande.statuses,
                                          'name',
                                          'transfert_pv_deliberation_to_minister',
                                          1
                                        ).reason
                                      "
                                    >
                                      <br>
                                      <i>Commentaire :</i>
                                      {{
                                        get_specific_nodes(
                                          demande.statuses,
                                          'name',
                                          'transfert_pv_deliberation_to_minister',
                                          1
                                        ).reason
                                      }}
                                    </span>
                                  </span>
                                  <span>
                                    <b-badge
                                      pill
                                      :variant="get_request_color(
                                        demande,
                                        'transfert_pv_deliberation_to_minister'
                                      )
                                      "
                                    >-</b-badge>
                                  </span>
                                </span>
                              </span>
                            </span>

                            <!-- ot decisions classification -->
                            <span v-if="request_have_ot_decisions(demande, 'all')">
                              <!-- for request autorisation d'exploitation et premier classement (show classement) -->
                              <span
                                v-if="
                                  in_audit_process(
                                    get_resquest_index(demande.id)
                                  ) == true &&
                                    request_have_ot_decisions(demande)
                                "
                                class="w-100"
                              >
                                <hr class="hr-primary">
                                <!-- 📌 ot : decision classification : {{ get_specific_nodes(demande.statuses, 'name', 'classification_deliberation_session_check', 1).name }} -->
                                <span class="d-flex justify-content-between">
                                  <span>
                                    <strong>
                                      {{
                                        get_current_step(
                                          demande.process_steps,
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'classification_deliberation_session_check',
                                            1
                                          ).name
                                        ).etape
                                      }}
                                    </strong>
                                    <span
                                      v-if="
                                        get_specific_nodes(
                                          demande.statuses,
                                          'name',
                                          'classification_deliberation_session_check',
                                          1
                                        ).reason
                                      "
                                    >
                                      <br>
                                      <i>Commentaire :</i>
                                      {{
                                        get_specific_nodes(
                                          demande.statuses,
                                          'name',
                                          'classification_deliberation_session_check',
                                          1
                                        ).reason
                                      }}
                                    </span>
                                  </span>
                                  <span>
                                    <b-badge
                                      pill
                                      :variant="get_request_color(
                                        demande,
                                        'classification_deliberation_session_check'
                                      )
                                      "
                                    >-</b-badge>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <!-- minister decisions classification -->
                            <span
                              v-if="
                                request_have_minister_decisions(demande, 'all')
                              "
                            >
                              <!-- for request autorisation d'exploitation et premier classement (show classement) -->
                              <span
                                v-if="
                                  in_audit_process(
                                    get_resquest_index(demande.id)
                                  ) == true &&
                                    request_have_minister_decisions(demande)
                                "
                                class="w-100"
                              >
                                <hr class="hr-primary">
                                <!-- 📌 minister : decision classification : {{ request_have_minister_decisions(demande) }} -->
                                <span
                                  v-if="
                                    request_have_minister_decisions(demande) &&
                                      in_audit_process(
                                        get_resquest_index(demande.id)
                                      ) == true
                                  "
                                  class="w-100"
                                >
                                  <span class="d-flex justify-content-between">
                                    <span>
                                      <strong>
                                        {{
                                          get_current_step(
                                            demande.process_steps,
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'transfert_all_documentation_to_minister',
                                              1
                                            ).name
                                          ).etape
                                        }}
                                      </strong>
                                      <span
                                        v-if="
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'transfert_all_documentation_to_minister',
                                            1
                                          ).reason
                                        "
                                      >
                                        <br>
                                        <i>Commentaire :</i>
                                        {{
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'transfert_all_documentation_to_minister',
                                            1
                                          ).reason
                                        }}
                                      </span>
                                    </span>
                                    <span>
                                      <b-badge
                                        pill
                                        :variant="get_request_color(
                                          demande,
                                          'transfert_all_documentation_to_minister'
                                        )
                                        "
                                      >-</b-badge>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>

                            <!-- 💊 demande actions 💊 -->
                            <span
                              v-if="
                                (!request_have_ot_decisions(demande) ||
                                  !request_have_minister_decisions(demande)) &&
                                  demande.next_node &&
                                  demande.next_node.length > 0
                              "
                            >
                              <hr class="hr-primary">
                              <!-- 📌 decisions actions  -->
                              <!-- decisions for OT or Minister -->
                              <span
                                v-if="
                                  !demande.next_node
                                    .hide_on_ministerial_decree_step &&
                                    demande.next_node.length !== 0
                                "
                              >
                                <span
                                  v-for="(
                                    decision, index_decision
                                  ) in demande.next_node"
                                  v-b-tooltip.hover.top.v-warning="(!isMinister &&
                                    decision.is_minister_validation &&
                                    'Le ministre doit valider cette étape.') ||
                                    (!isAdmin &&
                                      !isMinister &&
                                      !isMtcaAdmin &&
                                      !isOtAdmin &&
                                      'L\'agent traitant de l\'OT doit valider cette étape.')
                                  "
                                  :keys="index_decision"
                                  class="nopadding"
                                >
                                  <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    :variant="decision.class"
                                    class="my-1"
                                    block
                                    size="sm"
                                    :disabled="(!isMinister &&
                                      decision.is_minister_validation) ||
                                      (!isAdmin &&
                                        !isMtcaAdmin &&
                                        !isOtAdmin &&
                                        !isMinister)
                                    "
                                    @click="
                                      update_status_request(
                                        decision,
                                        demande.id,
                                        get_resquest_index(demande.id)
                                      )
                                    "
                                  >
                                    <b-spinner
                                      v-if="isLoading_update_state"
                                      small
                                    />
                                    <span v-if="!isLoading_update_state">
                                      {{ decision.action_label }}
                                    </span>
                                  </b-button>
                                </span>
                              </span>
                              <!-- comment -->
                              <b-form-textarea
                                v-if="
                                  !demande.next_node
                                    .hide_on_ministerial_decree_step
                                "
                                v-model="next_step_comment_request[
                                  get_resquest_index(demande.id)
                                ]
                                "
                                placeholder="Commentaire"
                                rows="1"
                                class=""
                              />
                            </span>
                          </span>
                          <!-- acknowledgement_of_receipt_minister_decisions -->
                          <span
                            v-if="
                              demande.statuses[0].name ===
                                'acknowledgement_of_receipt_minister_decisions'
                            "
                            v-b-tooltip.hover.top.v-warning="!isAdmin &&
                              !isMinister &&
                              !isMtcaAdmin &&
                              !isOtAdmin &&
                              'L\'agent traitant de l\'OT doit valider cette étape.'
                            "
                          >
                            <hr class="hr-primary">
                            <!-- demande.next_node :: {{ demande.next_node }} -->
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              :variant="demande.next_node.class"
                              class="mb-75 mb-1"
                              :disabled="!isAdmin && !isMtcaAdmin && !isOtAdmin"
                              size="sm"
                              block
                              @click="
                                update_status_request(
                                  demande.next_node,
                                  demande.id,
                                  get_resquest_index(demande.id)
                                )
                              "
                            >
                              <span>
                                <span v-if="!isLoading_update_state">
                                  {{ demande.next_node.action_label }}
                                </span>
                                <b-spinner
                                  v-else
                                  small
                                />
                              </span>
                            </b-button>
                          </span>
                        </div>
                      </div>
                    </span>

                    <!-- UEGT -->
                    <span
                      v-if="requests_by_sector_counts.uegt > 0"
                      class="h3 col-12 mt-2"
                    >
                      Guides de tourisme
                    </span>
                    <span
                      v-for="(demande, index_demande) in curr_arrete.demandes"
                      v-if="
                        requests_by_sector_counts.uegt > 0 &&
                          demande.selectedForm.sector == 'UEGT'
                      "
                      :key="demande.id"
                      class="col-6"
                    >
                      <div class="p-1 demandes">
                        <div>
                          <span
                            @click="
                              $router.push({
                                name: 'formulaires-details',
                                params: {
                                  id: demande.id,
                                  slug: demande.slug,
                                },
                              })
                            "
                          >
                            <h6>
                              <strong>Date de soummission de la demande :</strong>
                              {{ demande.created_at }}
                            </h6>
                            <h6>
                              <strong>Référence :</strong>
                              {{ demande.reference }}
                            </h6>
                            <h6>
                              <strong>Type de demande :</strong>
                              {{
                                getTypeDemandebyCode(demande.data.code_demande)
                              }}
                            </h6>
                            <h6>
                              <strong>Guide :</strong>
                              {{ demande.data.nom_promoteur }}
                              {{ demande.data.prenom_promoteur }}
                            </h6>
                          </span>

                          <h6
                            class="mt-2"
                            for="type"
                            @click.stop=""
                          >
                            <strong>Type de guide proposé en commission :</strong>
                            <v-select
                              id="type"
                              v-model="demande.type"
                              label="title"
                              :reduce="(option) => option.title"
                              :options="options_type_guide"
                              class="select-size-lg"
                              @input="
                                update_request(
                                  demande.id,
                                  get_resquest_index(demande.id),
                                  { type: demande.type }
                                )
                              "
                            />
                          </h6>
                          <h6
                            class="mt-2"
                            for="type"
                            @click.stop=""
                          >
                            <strong>Catégorie de guide proposé en commission :</strong>
                            <v-select
                              id="type"
                              v-model="demande.categorie"
                              label="title"
                              :reduce="(option) => option.title"
                              :options="options_categorie_guide"
                              class="select-size-lg"
                              @input="
                                update_request(
                                  demande.id,
                                  get_resquest_index(demande.id),
                                  { categorie: demande.categorie }
                                )
                              "
                            />
                          </h6>
                          <h6
                            @click.stop=""
                          >
                            <strong>Affection régionale demandée par le requérant
                              :</strong>
                            <br>
                            <span>Affectation 1 :
                              {{ demande.data.affectation_1 }}</span>
                            <br>
                            <span v-if="demande.data.affectation_2">Affectation 2 :
                              {{ demande.data.affectation_2 }}</span>
                            <br>
                            <span v-if="demande.data.affectation_3">Affectation 3 :
                              {{ demande.data.affectation_3 }}</span>
                            <br>
                          </h6>
                          <h6
                            class="mt-2"
                            for="type"
                            @click.stop=""
                          >
                            <strong>Affectation de guide proposé en commission : </strong>
                            <v-select
                              id="type"
                              v-model="demande.affectation"
                              label="title"
                              :reduce="(option) => option.title"
                              :options="options_affectation_guide"
                              class="select-size-lg"
                              @input="
                                update_request(
                                  demande.id,
                                  get_resquest_index(demande.id),
                                  { affectation: demande.affectation }
                                )
                              "
                            />
                          </h6>

                          <h6
                            v-if="demande.data.specialite_1"
                            @click.stop=""
                          >
                            <strong>Spécialité demandée par le requérant :</strong>
                            <br>
                            <span>Specialite 1 :
                              {{ demande.data.specialite_1 }}</span>
                            <br>
                            <span v-if="demande.data.specialite_2">Specialite 2 :
                              {{ demande.data.specialite_2 }}</span>
                            <br>
                            <span v-if="demande.data.specialite_3">Specialite 3 :
                              {{ demande.data.specialite_3 }}</span>
                            <br>
                          </h6>
                          <h6
                            class="mt-2"
                            for="type"
                            @click.stop=""
                          >
                            <strong>Spécialité de guide proposé en commission :</strong>
                            <v-select
                              id="type"
                              v-model="demande.specialite"
                              label="title"
                              :reduce="(option) => option.title"
                              :options="options_affectation_guide"
                              class="select-size-lg"
                              @input="
                                update_request(
                                  demande.id,
                                  get_resquest_index(demande.id),
                                  { specialite: demande.specialite }
                                )
                              "
                            />
                          </h6>

                          <!-- showing visits controls results ---------------------------------------------->
                          <!-- have_metting_with_applicant -->
                          <span @click.stop="">
                            <!-- <span
                              v-if="demande.have_metting_with_applicant"
                              class="row mt-1"
                            >
                              <span class="col-5"
                                ><strong
                                  >Entretien avec le requérant</strong
                                ></span
                              >
                              <span class="col-7">
                                <hr class="hr-primary" />
                              </span>
                              <span
                                class="col-12"
                                v-if="demande.applicant_responsable_id"
                              >
                                <strong>Responsable visite: </strong
                                >{{
                                  get_user_by_id(
                                    demande.applicant_responsable_id
                                  ) &&
                                  get_user_by_id(demande.applicant_responsable_id)
                                    .fullname
                                }}
                                <br />
                              </span>
                            </span> -->
                            <!-- have_morality_investigation -->
                            <!-- <span v-if="demande.have_morality_investigation" class="row mt-1" >
                              <span class="col-5"><strong>Enquête de moralité</strong></span>
                              <span class="col-7"> <hr class="hr-primary"> </span>
                              <span class="col-12" v-if="demande.morality_investigation_responsable_id">
                                <strong>Responsable visite: </strong>{{ get_user_by_id(demande.morality_investigation_responsable_id) && get_user_by_id(demande.morality_investigation_responsable_id).fullname }} <br>
                                 <strong>Date du RDV: </strong> date
                              </span>
                            </span> -->

                            <!-- showing decisions and choises control ---------------------------------------------------->
                            <span>
                              <!-- ot decisions classic -->
                              <span v-if="request_have_ot_decisions(demande, 'all')">
                                <!-- for classic request -->
                                <span class="w-100">
                                  <hr class="hr-primary">
                                  <!-- 📌  ot : decision autorisation -->
                                  <!-- 📌 {{ get_specific_nodes(demande.statuses, 'name', 'deliberation_session_check', 1) }} -->
                                  <span class="d-flex justify-content-between">
                                    <span>
                                      <strong>
                                        {{
                                          get_current_step(
                                            demande.process_steps,
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'deliberation_session_check',
                                              1
                                            ).name
                                          ).etape
                                        }}
                                      </strong>
                                      <span
                                        v-if="
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'deliberation_session_check',
                                            1
                                          ).reason
                                        "
                                      >
                                        <br>
                                        <i>Commentaire :</i>
                                        {{
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'deliberation_session_check',
                                            1
                                          ).reason
                                        }}
                                      </span>
                                    </span>
                                    <span>
                                      <b-badge
                                        pill
                                        :variant="get_request_color(
                                          demande,
                                          'deliberation_session_check'
                                        )
                                        "
                                      >-</b-badge>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <!-- minister decisions classic -->
                              <span
                                v-if="
                                  request_have_minister_decisions(
                                    demande,
                                    'all'
                                  )
                                "
                              >
                                <!-- for classic request -->
                                <span class="w-100">
                                  <hr class="hr-primary">
                                  <!-- 📌 minister : decision autorisation -->
                                  <span class="d-flex justify-content-between">
                                    <span>
                                      <strong>
                                        {{
                                          get_current_step(
                                            demande.process_steps,
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'transfert_pv_deliberation_to_minister',
                                              1
                                            ).name
                                          ).etape
                                        }}
                                      </strong>
                                      <span
                                        v-if="
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'transfert_pv_deliberation_to_minister',
                                            1
                                          ).reason
                                        "
                                      >
                                        <br>
                                        <i>Commentaire :</i>
                                        {{
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'transfert_pv_deliberation_to_minister',
                                            1
                                          ).reason
                                        }}
                                      </span>
                                    </span>
                                    <span>
                                      <b-badge
                                        pill
                                        :variant="get_request_color(
                                          demande,
                                          'transfert_pv_deliberation_to_minister'
                                        )
                                        "
                                      >-</b-badge>
                                    </span>
                                  </span>
                                </span>
                              </span>

                              <!-- ot decisions classification -->
                              <span v-if="request_have_ot_decisions(demande, 'all')">
                                <!-- for request autorisation d'exploitation et premier classement (show classement) -->
                                <span
                                  v-if="
                                    in_audit_process(
                                      get_resquest_index(demande.id)
                                    ) == true &&
                                      request_have_ot_decisions(demande)
                                  "
                                  class="w-100"
                                >
                                  <hr class="hr-primary">
                                  <!-- 📌 ot : decision classification : {{ get_specific_nodes(demande.statuses, 'name', 'classification_deliberation_session_check', 1).name }} -->
                                  <span class="d-flex justify-content-between">
                                    <span>
                                      <strong>
                                        {{
                                          get_current_step(
                                            demande.process_steps,
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'classification_deliberation_session_check',
                                              1
                                            ).name
                                          ).etape
                                        }}
                                      </strong>
                                      <span
                                        v-if="
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'classification_deliberation_session_check',
                                            1
                                          ).reason
                                        "
                                      >
                                        <br>
                                        <i>Commentaire :</i>
                                        {{
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'classification_deliberation_session_check',
                                            1
                                          ).reason
                                        }}
                                      </span>
                                    </span>
                                    <span>
                                      <b-badge
                                        pill
                                        :variant="get_request_color(
                                          demande,
                                          'classification_deliberation_session_check'
                                        )
                                        "
                                      >-</b-badge>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <!-- minister decisions classification -->
                              <span
                                v-if="
                                  request_have_minister_decisions(
                                    demande,
                                    'all'
                                  )
                                "
                              >
                                <!-- for request autorisation d'exploitation et premier classement (show classement) -->
                                <span
                                  v-if="
                                    in_audit_process(
                                      get_resquest_index(demande.id)
                                    ) == true &&
                                      request_have_minister_decisions(demande)
                                  "
                                  class="w-100"
                                >
                                  <hr class="hr-primary">
                                  <!-- 📌 minister : decision classification : {{ request_have_minister_decisions(demande) }} -->
                                  <span
                                    v-if="
                                      request_have_minister_decisions(
                                        demande
                                      ) &&
                                        in_audit_process(
                                          get_resquest_index(demande.id)
                                        ) == true
                                    "
                                    class="w-100"
                                  >
                                    <span class="d-flex justify-content-between">
                                      <span>
                                        <strong>
                                          {{
                                            get_current_step(
                                              demande.process_steps,
                                              get_specific_nodes(
                                                demande.statuses,
                                                'name',
                                                'transfert_all_documentation_to_minister',
                                                1
                                              ).name
                                            ).etape
                                          }}
                                        </strong>
                                        <span
                                          v-if="
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'transfert_all_documentation_to_minister',
                                              1
                                            ).reason
                                          "
                                        >
                                          <br>
                                          <i>Commentaire :</i>
                                          {{
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'transfert_all_documentation_to_minister',
                                              1
                                            ).reason
                                          }}
                                        </span>
                                      </span>
                                      <span>
                                        <b-badge
                                          pill
                                          :variant="get_request_color(
                                            demande,
                                            'transfert_all_documentation_to_minister'
                                          )
                                          "
                                        >-</b-badge>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>

                              <!-- 💊 demande actions 💊 -->
                              <span
                                v-if="
                                  (!request_have_ot_decisions(demande) ||
                                    !request_have_minister_decisions(
                                      demande
                                    )) &&
                                    demande.next_node &&
                                    demande.next_node.length > 0
                                "
                              >
                                <hr class="hr-primary">
                                <!-- 📌 decisions actions  -->
                                <!-- decisions for OT or Minister -->
                                <span
                                  v-if="
                                    !demande.next_node
                                      .hide_on_ministerial_decree_step &&
                                      demande.next_node.length !== 0
                                  "
                                >
                                  <span
                                    v-for="(
                                      decision, index_decision
                                    ) in demande.next_node"
                                    v-b-tooltip.hover.top.v-warning="(!isMinister &&
                                      decision.is_minister_validation &&
                                      'Le ministre doit valider cette étape.') ||
                                      (!isAdmin &&
                                        !isMinister &&
                                        !isMtcaAdmin &&
                                        !isOtAdmin &&
                                        'L\'agent traitant de l\'OT doit valider cette étape.')
                                    "
                                    :keys="index_decision"
                                    class="nopadding"
                                  >
                                    <b-button
                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                      :variant="decision.class"
                                      class="my-1"
                                      block
                                      size="sm"
                                      :disabled="(!isMinister &&
                                        decision.is_minister_validation) ||
                                        (!isAdmin &&
                                          !isMtcaAdmin &&
                                          !isOtAdmin &&
                                          !isMinister)
                                      "
                                      @click="
                                        update_status_request(
                                          decision,
                                          demande.id,
                                          get_resquest_index(demande.id)
                                        )
                                      "
                                    >
                                      <b-spinner
                                        v-if="isLoading_update_state"
                                        small
                                      />
                                      <span v-if="!isLoading_update_state">
                                        {{ decision.action_label }}
                                      </span>
                                    </b-button>
                                  </span>
                                </span>
                                <!-- comment -->
                                <b-form-textarea
                                  v-if="
                                    !demande.next_node
                                      .hide_on_ministerial_decree_step
                                  "
                                  v-model="next_step_comment_request[
                                    get_resquest_index(demande.id)
                                  ]
                                  "
                                  placeholder="Commentaire"
                                  rows="1"
                                  class=""
                                />
                              </span>
                            </span>
                            <!-- acknowledgement_of_receipt_minister_decisions -->
                            <span
                              v-if="
                                demande.statuses[0].name ===
                                  'acknowledgement_of_receipt_minister_decisions'
                              "
                              v-b-tooltip.hover.top.v-warning="!isAdmin &&
                                !isMinister &&
                                !isMtcaAdmin &&
                                !isOtAdmin &&
                                'L\'agent traitant de l\'OT doit valider cette étape.'
                              "
                            >
                              <hr class="hr-primary">
                              <!-- demande.next_node :: {{ demande.next_node }} -->
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                :variant="demande.next_node.class"
                                class="mb-75 mb-1"
                                :disabled="!isAdmin && !isMtcaAdmin && !isOtAdmin
                                "
                                size="sm"
                                block
                                @click="
                                  update_status_request(
                                    demande.next_node,
                                    demande.id,
                                    get_resquest_index(demande.id)
                                  )
                                "
                              >
                                <span>
                                  <span v-if="!isLoading_update_state">
                                    {{ demande.next_node.action_label }}
                                  </span>
                                  <b-spinner
                                    v-else
                                    small
                                  />
                                </span>
                              </b-button>
                            </span>
                          </span>
                        </div>
                      </div>
                    </span>

                    <!-- UER -->
                    <span
                      v-if="requests_by_sector_counts.uer > 0"
                      class="h3 col-12 mt-2"
                    >
                      Etablissements de restauration
                    </span>
                    <span
                      v-for="(demande, index_demande) in curr_arrete.demandes"
                      v-if="
                        requests_by_sector_counts.uer > 0 &&
                          demande.selectedForm.sector == 'UER'
                      "
                      :key="demande.id"
                      class="col-6"
                    >
                      <div class="p-1 demandes">
                        <div>
                          <span
                            @click="
                              $router.push({
                                name: 'formulaires-details',
                                params: {
                                  id: demande.id,
                                  slug: demande.slug,
                                },
                              })
                            "
                          >
                            <h6>
                              <strong>
                                Date de soummission de la demande :</strong>
                              {{ demande.created_at }}
                            </h6>
                            <h6>
                              <strong>Référence :</strong>
                              {{ demande.reference }}
                            </h6>
                            <h6>
                              <strong>Type de demande :</strong>
                              {{
                                getTypeDemandebyCode(demande.data.code_demande)
                              }}
                            </h6>
                            <h6>
                              <strong>Etablissement :</strong>
                              {{
                                demande.data.raison_sociale !== null
                                  ? demande.data.raison_sociale
                                  : '--'
                              }}
                            </h6>
                            <h6>
                              <strong>Promoteur :</strong>
                              {{ demande.data.nom_promoteur }}
                              {{ demande.data.prenom_promoteur }}
                            </h6>
                          </span>

                          <h6
                            class="mt-2"
                            for="type"
                            @click.stop=""
                          >
                            <strong>Type d'établissement porposé en commission
                              :</strong>
                            <v-select
                              id="type"
                              v-model="demande.type_etablissement"
                              label="title"
                              :reduce="(option) => option.title"
                              :options="options_type_secteur_restauration"
                              class="select-size-lg"
                              @input="
                                update_request(
                                  demande.id,
                                  get_resquest_index(demande.id),
                                  {
                                    type_etablissement:
                                      demande.type_etablissement,
                                  }
                                )
                              "
                            />
                          </h6>
                          <h6 @click.stop="">
                            <strong>Classement proposé en commission :</strong>
                            <v-select
                              id="type"
                              v-model="demande.classement"
                              label="title"
                              :reduce="(option) => option.value"
                              :options="options_classement"
                              class="select-size-lg"
                              @input="
                                update_request(
                                  demande.id,
                                  get_resquest_index(demande.id),
                                  { classement: demande.classement }
                                )
                              "
                            />
                          </h6>

                          <span @click.stop="">
                            <!-- showing visits controls results ---------------------------------------------->
                            <!-- autorisation control -->
                            <span
                              v-if="demande.have_autorisation_visit"
                              class="row mt-1"
                            >
                              <span class="col-5"><strong>Visite du contrôle technique</strong></span>
                              <span class="col-7">
                                <hr class="hr-primary">
                              </span>
                              <span
                                v-if="demande.technical_visitor_id"
                                class="col-12"
                              >
                                <strong>Responsable de la visite: </strong>{{
                                  get_user_by_id(
                                    demande.technical_visitor_id
                                  ) &&
                                    get_user_by_id(demande.technical_visitor_id)
                                      .fullname
                                }}
                                <br>
                                <!-- <strong>Date du RDV: </strong> date -->
                              </span>
                            </span>
                            <!-- classification control -->
                            <span
                              v-if="demande.have_classification_visit"
                              class="row mt-1"
                            >
                              <span class="col-5"><strong>Visite de classement</strong></span>
                              <span class="col-7">
                                <hr class="hr-primary">
                              </span>
                              <span
                                v-if="demande.classification_visitor_id"
                                class="col-12"
                              >
                                <strong>Responsable de la visite: </strong>
                                {{
                                  get_user_by_id(
                                    demande.classification_visitor_id
                                  ) &&
                                    get_user_by_id(
                                      demande.classification_visitor_id
                                    ).fullname
                                }}
                                <br>
                                <!-- <strong>Date du RDV: </strong> date -->
                              </span>
                            </span>
                            <!-- audit control -->
                            <span
                              v-if="demande.have_exam_visit"
                              class="row mt-1"
                            >
                              <span class="col-5"><strong>Audit mystère</strong></span>
                              <span class="col-7">
                                <hr class="hr-primary">
                              </span>
                              <span
                                v-if="demande.auditor_id"
                                class="col-12"
                              >
                                <strong>Responsable visite: </strong>{{
                                  get_user_by_id(demande.auditor_id) &&
                                    get_user_by_id(demande.auditor_id).fullname
                                }}
                                <br>
                                <!-- <strong>Date du RDV: </strong> date -->
                              </span>
                            </span>

                            <!-- showing decisions and choises control ---------------------------------------------------->
                            <span>
                              <!-- ot decisions classic -->
                              <span v-if="request_have_ot_decisions(demande, 'all')">
                                <!-- for classic request -->
                                <span class="w-100">
                                  <hr class="hr-primary">
                                  <!-- 📌  ot : decision autorisation -->
                                  <!-- 📌 {{ get_specific_nodes(demande.statuses, 'name', 'deliberation_session_check', 1) }} -->
                                  <span class="d-flex justify-content-between">
                                    <span>
                                      <strong>
                                        {{
                                          get_current_step(
                                            demande.process_steps,
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'deliberation_session_check',
                                              1
                                            ).name
                                          ).etape
                                        }}
                                      </strong>
                                      <span
                                        v-if="
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'deliberation_session_check',
                                            1
                                          ).reason
                                        "
                                      >
                                        <br>
                                        <i>Commentaire :</i>
                                        {{
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'deliberation_session_check',
                                            1
                                          ).reason
                                        }}
                                      </span>
                                    </span>
                                    <span>
                                      <b-badge
                                        pill
                                        :variant="get_request_color(
                                          demande,
                                          'deliberation_session_check'
                                        )
                                        "
                                      >-</b-badge>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <!-- minister decisions classic -->
                              <span
                                v-if="
                                  request_have_minister_decisions(
                                    demande,
                                    'all'
                                  )
                                "
                              >
                                <!-- for classic request -->
                                <span class="w-100">
                                  <hr class="hr-primary">
                                  <!-- 📌 minister : decision autorisation -->
                                  <span class="d-flex justify-content-between">
                                    <span>
                                      <strong>
                                        {{
                                          get_current_step(
                                            demande.process_steps,
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'transfert_pv_deliberation_to_minister',
                                              1
                                            ).name
                                          ).etape
                                        }}
                                      </strong>
                                      <span
                                        v-if="
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'transfert_pv_deliberation_to_minister',
                                            1
                                          ).reason
                                        "
                                      >
                                        <br>
                                        <i>Commentaire :</i>
                                        {{
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'transfert_pv_deliberation_to_minister',
                                            1
                                          ).reason
                                        }}
                                      </span>
                                    </span>
                                    <span>
                                      <b-badge
                                        pill
                                        :variant="get_request_color(
                                          demande,
                                          'transfert_pv_deliberation_to_minister'
                                        )
                                        "
                                      >-</b-badge>
                                    </span>
                                  </span>
                                </span>
                              </span>

                              <!-- ot decisions classification -->
                              <span v-if="request_have_ot_decisions(demande, 'all')">
                                <!-- for request autorisation d'exploitation et premier classement (show classement) -->
                                <span
                                  v-if="
                                    in_audit_process(
                                      get_resquest_index(demande.id)
                                    ) == true &&
                                      request_have_ot_decisions(demande)
                                  "
                                  class="w-100"
                                >
                                  <hr class="hr-primary">
                                  <!-- 📌 ot : decision classification : {{ get_specific_nodes(demande.statuses, 'name', 'classification_deliberation_session_check', 1).name }} -->
                                  <span class="d-flex justify-content-between">
                                    <span>
                                      <strong>
                                        {{
                                          get_current_step(
                                            demande.process_steps,
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'classification_deliberation_session_check',
                                              1
                                            ).name
                                          ).etape
                                        }}
                                      </strong>
                                      <span
                                        v-if="
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'classification_deliberation_session_check',
                                            1
                                          ).reason
                                        "
                                      >
                                        <br>
                                        <i>Commentaire :</i>
                                        {{
                                          get_specific_nodes(
                                            demande.statuses,
                                            'name',
                                            'classification_deliberation_session_check',
                                            1
                                          ).reason
                                        }}
                                      </span>
                                    </span>
                                    <span>
                                      <b-badge
                                        pill
                                        :variant="get_request_color(
                                          demande,
                                          'classification_deliberation_session_check'
                                        )
                                        "
                                      >-</b-badge>
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <!-- minister decisions classification -->
                              <span
                                v-if="
                                  request_have_minister_decisions(
                                    demande,
                                    'all'
                                  )
                                "
                              >
                                <!-- for request autorisation d'exploitation et premier classement (show classement) -->
                                <span
                                  v-if="
                                    in_audit_process(
                                      get_resquest_index(demande.id)
                                    ) == true &&
                                      request_have_minister_decisions(demande)
                                  "
                                  class="w-100"
                                >
                                  <hr class="hr-primary">
                                  <!-- 📌 minister : decision classification : {{ request_have_minister_decisions(demande) }} -->
                                  <span
                                    v-if="
                                      request_have_minister_decisions(
                                        demande
                                      ) &&
                                        in_audit_process(
                                          get_resquest_index(demande.id)
                                        ) == true
                                    "
                                    class="w-100"
                                  >
                                    <span class="d-flex justify-content-between">
                                      <span>
                                        <strong>
                                          {{
                                            get_current_step(
                                              demande.process_steps,
                                              get_specific_nodes(
                                                demande.statuses,
                                                'name',
                                                'transfert_all_documentation_to_minister',
                                                1
                                              ).name
                                            ).etape
                                          }}
                                        </strong>
                                        <span
                                          v-if="
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'transfert_all_documentation_to_minister',
                                              1
                                            ).reason
                                          "
                                        >
                                          <br>
                                          <i>Commentaire :</i>
                                          {{
                                            get_specific_nodes(
                                              demande.statuses,
                                              'name',
                                              'transfert_all_documentation_to_minister',
                                              1
                                            ).reason
                                          }}
                                        </span>
                                      </span>
                                      <span>
                                        <b-badge
                                          pill
                                          :variant="get_request_color(
                                            demande,
                                            'transfert_all_documentation_to_minister'
                                          )
                                          "
                                        >-</b-badge>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>

                              <!-- 💊 demande actions 💊 -->
                              <span
                                v-if="
                                  (!request_have_ot_decisions(demande) ||
                                    !request_have_minister_decisions(
                                      demande
                                    )) &&
                                    demande.next_node &&
                                    demande.next_node.length > 0
                                "
                              >
                                <hr class="hr-primary">
                                <!-- 📌 decisions actions  -->
                                <!-- decisions for OT or Minister -->
                                <span
                                  v-if="
                                    !demande.next_node
                                      .hide_on_ministerial_decree_step &&
                                      demande.next_node.length !== 0
                                  "
                                >
                                  <span
                                    v-for="(
                                      decision, index_decision
                                    ) in demande.next_node"
                                    v-b-tooltip.hover.top.v-warning="(!isMinister &&
                                      decision.is_minister_validation &&
                                      'Le ministre doit valider cette étape.') ||
                                      (!isAdmin &&
                                        !isMinister &&
                                        !isMtcaAdmin &&
                                        !isOtAdmin &&
                                        'L\'agent traitant de l\'OT doit valider cette étape.')
                                    "
                                    :keys="index_decision"
                                    class="nopadding"
                                  >
                                    <b-button
                                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                      :variant="decision.class"
                                      class="my-1"
                                      block
                                      size="sm"
                                      :disabled="(!isMinister &&
                                        decision.is_minister_validation) ||
                                        (!isAdmin &&
                                          !isMtcaAdmin &&
                                          !isOtAdmin &&
                                          !isMinister)
                                      "
                                      @click="
                                        update_status_request(
                                          decision,
                                          demande.id,
                                          get_resquest_index(demande.id)
                                        )
                                      "
                                    >
                                      <b-spinner
                                        v-if="isLoading_update_state"
                                        small
                                      />
                                      <span v-if="!isLoading_update_state">
                                        {{ decision.action_label }}
                                      </span>
                                    </b-button>
                                  </span>
                                </span>
                                <!-- comment -->
                                <b-form-textarea
                                  v-if="
                                    !demande.next_node
                                      .hide_on_ministerial_decree_step
                                  "
                                  v-model="next_step_comment_request[
                                    get_resquest_index(demande.id)
                                  ]
                                  "
                                  placeholder="Commentaire"
                                  rows="1"
                                  class=""
                                />
                              </span>
                            </span>
                            <!-- acknowledgement_of_receipt_minister_decisions -->
                            <span
                              v-if="
                                demande.statuses[0].name ===
                                  'acknowledgement_of_receipt_minister_decisions'
                              "
                              v-b-tooltip.hover.top.v-warning="!isMinister &&
                                !isMtcaAdmin &&
                                !isOtAdmin &&
                                'L\'agent traitant de l\'OT doit valider cette étape.'
                              "
                            >
                              <hr class="hr-primary">
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                :variant="demande.next_node.class"
                                class="mb-75 mb-1"
                                :disabled="!isAdmin && !isMtcaAdmin && !isOtAdmin
                                "
                                size="sm"
                                block
                                @click="
                                  update_status_request(
                                    demande.next_node,
                                    demande.id,
                                    get_resquest_index(demande.id)
                                  )
                                "
                              >
                                <span>
                                  <span v-if="!isLoading_update_state">
                                    {{ demande.next_node.action_label }}
                                  </span>
                                  <b-spinner
                                    v-else
                                    small
                                  />
                                </span>
                              </b-button>
                            </span>
                          </span>
                        </div>
                      </div>
                    </span>
                  </b-card-body>
                </b-card>
              </div>

              <!-- PV  -->
              <div>
                <b-card id="renseignementsgeneraux">
                  <b-card-header class="d-flex justify-content-between">
                    <h3>PROCÈS VERBAL</h3>
                    <span class="d-flex flex-column">
                      <b-button
                        v-if="current_sector == 'UEH'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="showPv('modal-pv-ueh')"
                      >
                        <i class="las la-eye" />
                        {{ $t('bylaws_pv.show_modal') }}
                      </b-button>
                      <b-button
                        v-if="current_sector == 'UER'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="showPv('modal-pv-uer')"
                      >
                        <i class="las la-eye" />
                        {{ $t('bylaws_pv.show_modal') }}
                      </b-button>
                      <b-button
                        v-if="current_sector == 'UEAV'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="showPv('modal-pv-ueav')"
                      >
                        <i class="las la-eye" />
                        {{ $t('bylaws_pv.show_modal') }}
                      </b-button>
                      <b-button
                        v-if="current_sector == 'UEGT'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="showPv('modal-pv-uegt')"
                      >
                        <i class="las la-eye" />
                        {{ $t('bylaws_pv.show_modal') }}
                      </b-button>
                    </span>
                  </b-card-header>
                  <hr>
                  <b-card-body />
                </b-card>
              </div>

              <!-- ARRETE  -->
              <div v-show="curr_arrete.arrete_data">
                arretes_links : {{ arretes_links }}
                <b-card id="renseignementsgeneraux">
                  <b-card-header class="d-flex justify-content-between">
                    <h3>ARRÊTÉ</h3>
                    <span
                      v-if="arretes_links.length > 0"
                      class="d-flex flex-column"
                    >
                      <b-button
                        v-for="(arrete, arrete_k) in arretes_links"
                        :key="arrete_k"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        @click="donwload_file_by_url(arrete.url)"
                      >
                        <i class="las la-file-pdf" />
                        {{ arrete.request }}
                        <b-spinner
                          v-if="isLoadingDownloadArrete"
                          small
                        />
                      </b-button>
                    </span>
                  </b-card-header>
                  <hr>

                  <b-card-body
                    v-if="curr_arrete.arrete_data"
                    class="row"
                  >
                    <br>
                    <span class="h3 col-12"> En tête </span>
                    <!-- date -->
                    <h6
                      class="col-6"
                      for="type"
                    >
                      <b-form-group class="w-100">
                        <span class="d-flex justify-content-between">
                          <label for="new_request"> Date : </label>
                        </span>
                        <b-input-group>
                          <b-form-datepicker
                            v-model="arrete_data_input_date"
                            class=""
                            placeholder="Aucune date sélectionnée"
                          />
                        </b-input-group>
                      </b-form-group>
                    </h6>
                    <!-- bp -->
                    <h6
                      class="col-6"
                      for="type"
                    >
                      <b-form-group class="w-100">
                        <span class="d-flex justify-content-between">
                          <label for="bp"> Boîte postale : </label>
                        </span>
                        <b-input-group>
                          <b-form-input
                            id="bp"
                            v-model="curr_arrete.arrete_data.boite_postale"
                            class="w-100"
                            @change="update_arrete('arrete_data')"
                          />
                        </b-input-group>
                      </b-form-group>
                    </h6>
                    <!-- telephone -->
                    <h6 class="col-6">
                      <b-form-group class="w-100">
                        <span class="d-flex justify-content-between">
                          <label for="bp"> Téléphone : </label>
                        </span>
                        <b-input-group>
                          <b-form-input
                            id="bp"
                            v-model="curr_arrete.arrete_data.telephone"
                            class="w-100"
                            @change="update_arrete('arrete_data')"
                          />
                        </b-input-group>
                      </b-form-group>
                    </h6>
                    <!-- mail -->
                    <h6 class="col-6">
                      <b-form-group class="w-100">
                        <span class="d-flex justify-content-between">
                          <label for="bp"> Email : </label>
                        </span>
                        <b-input-group>
                          <b-form-input
                            id="bp"
                            v-model="curr_arrete.arrete_data.mail"
                            class="w-100"
                            @change="update_arrete('arrete_data')"
                          />
                        </b-input-group>
                      </b-form-group>
                    </h6>
                    <!-- ampliation -->
                    <h6 class="col-12">
                      <b-form-group class="w-100">
                        <span class="d-flex justify-content-between">
                          <label for="bp">
                            Ampliations :
                            {{
                              ampliation_attributes &&
                                ampliation_attributes.length
                            }}
                          </label>
                        </span>
                        <b-input-group class="row">
                          <span
                            v-for="(
                              attribut, attrib_k
                            ) in ampliation_attributes"
                            :key="attrib_k"
                            class="col-4 w-100 d-flex justify-row mb-1"
                          >
                            <span class="my-auto col-8">{{ attribut[0] }}
                            </span>
                            <span class="col-3">
                              <b-form-input
                                v-model.number="curr_arrete.arrete_data.ampliations[
                                  attribut[0]
                                ]
                                "
                                size="sm"
                                type="number"
                                min="1"
                              />
                            </span>
                            <span
                              v-b-tooltip.hover.right="'Supprimer cette ampliation : ' + attribut[0]
                              "
                              class="my-auto col-1"
                              @click="delete_ampliation(attribut[0])"
                            >
                              <i class="las la-trash" />
                            </span>
                          </span>
                          <span class="col-4 w-100 d-flex justify-row mb-1">
                            <b-form-input
                              v-model="new_ampliation_attributes.structure"
                              v-b-tooltip.hover.bottom="'Structure'"
                              size="sm"
                              type="text"
                              class="col-8"
                            />
                            <b-form-input
                              v-model.number="new_ampliation_attributes.copies"
                              v-b-tooltip.hover.bottom="'Nombre de copies'"
                              size="sm"
                              type="number"
                              min="1"
                              class="col-3"
                            />
                            <span
                              v-b-tooltip.hover.right="'Ajouter une ampliation'"
                              class="my-auto col-1"
                              @click="add_ampliation()"
                            >
                              <i class="las la-plus" />
                            </span>
                          </span>
                        </b-input-group>
                      </b-form-group>
                    </h6>
                  </b-card-body>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- side bar -->
        <div class="options-sidebar">
          <div
            v-if="show_vizualisation_section"
            cols="2"
            xl="2"
            md="2"
            class="sidecard row"
          >
            fichier
          </div>
          <div
            v-else
            cols="2"
            xl="2"
            md="2"
            class="sidecard"
          >
            <b-card>
              <span
                v-if="!loading_steps_panel"
                class="py-1"
              >
                <!-- ministerial_decree_number -->
                <span
                  v-if="!can_edit_arrete"
                  class="mb-2"
                >
                  <label for="numero_arrete">Numéro d'arrêté</label>
                  <b-form-input
                    id="numero_arrete"
                    v-model="curr_arrete.numero"
                    disabled
                  />
                </span>
                <span
                  v-else
                  class="mb-2"
                >
                  <label for="numero_arrete">Numéro d'arrêté</label>
                  <b-form-input
                    id="numero_arrete"
                    v-model="curr_arrete.numero"
                    type="text"
                    @change="update_arrete('numero')"
                  />
                </span>
                <!-- <span class="mt-5"> ok </span> -->

                <!-- badge décompte durée traitement  -->
                <b-badge
                  v-if="
                    current_state && next_state && next_state.treatment_section
                  "
                  pill
                  variant="light-primary"
                  class="w-100 mb-1 badge-glow py-1 my-1"
                >
                  En traitement depuis
                  {{
                    calculate_passed_time_in_days(
                      curr_arrete.statuses[0].created_at
                    )
                  }}
                  jour(s)
                  <span v-if="next_state.duration || next_state.parent_process">
                    <br><br>
                    <span v-if="next_state.duration">
                      Vous avez {{ next_state.duration }} jours pour valider
                      cette étape.</span>
                    <span v-if="next_state.parent_process">
                      Vous avez
                      {{
                        get_current_step(states, next_state.parent_process)
                          .duration
                      }}
                      jours pour valider cette étape.
                    </span>
                  </span>
                </b-badge>
                <!-- bail step control  -->
                <!--
                    v-b-tooltip.hover.top.v-warning="(!isMinister &&
                    next_state &&
                    !next_state.is_minister_validation &&
                    'L\'agent traitant de l\'OT doit valider cette étape.')
                  "
                -->
                <span>
                  <!--courant node-->
                  <b-button
                    v-if="next_state && curr_decisions.length === 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :disabled="(curr_arrete && !next_state) ||
                      (!isMinister && next_state.is_minister_validation) ||
                      (isMinister && !next_state.is_minister_validation)
                    "
                    :variant="next_state.class"
                    class="mb-75 mb-1"
                    size="sm"
                    block
                    @click="update_status_arrete()"
                  >
                    <span>
                      <!-- {{ next_state }} -->
                      <span v-if="!isLoading_update_state">
                        {{ next_state.action_label }}
                      </span>
                      <b-spinner
                        v-else
                        small
                      />
                    </span>
                  </b-button>
                  <!-- step comment -->
                  <b-form-textarea
                    v-if="current_state && !current_state.final_state"
                    v-model="next_step_comment"
                    placeholder="Commentaire"
                    rows="1"
                    class=""
                  />
                </span>
              </span>
              <span
                v-else
                class="py-1 d-flex justify-content-center"
              >
                <b-spinner small />
              </span>
            </b-card>
          </div>
        </div>
        <!-- /side bar -->
      </div>
    </validation-observer>

    <!-- Pv ueh -->

    <b-modal
      id="modal-pv-ueh"
      ref="modal-pv-ueh"
      ok-only
      content-class="shadow"
      :title="$t('bylaws_pv.modal_title')"
      :ok-title="$t('form_details.close_modal')"
      centered
      size="xl"
      no-close-on-backdrop
      @ok=""
    >
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          size="sm"
          @click="downloadPDF('modal-pv-ueh')"
        >
          <b-spinner
            v-if="isDownloading"
            small
          />
          {{ $t('form_details.download_pv') }}
        </b-button>
      </div>
      <div class="px-4 py-3">

        <div class="text-center">
          <b-row>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.rb') }}</h5>
              </p>
            </b-col>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.mtca') }}</h5>
              </p>
            </b-col>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.ddt') }}</h5>
              </p>

            </b-col>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.bt') }}</h5>
              </p>

            </b-col>

            <b-col class="intro-page">
              <h4>
                {{ $t('bylaws_pv.title_ueh') }}
              </h4>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col
            cols="12"
            class=""
          >
            <span class="text-underlined"> {{ $t('bylaws_pv.ref_head') }} </span> :
            <ul class="mt-1">
              <li><span class="d-flex align-items-center ">{{ $t('bylaws_pv.ref_elem1') }} <b-form-input
                class="w-25"
                type="number"
              /></span></li>
              <li><span class="d-flex align-items-center">{{ $t('bylaws_pv.ref_elem2') }} <b-form-input
                class="w-25"
                type="number"
              /></span></li>
            </ul>
          </b-col>

          <b-col
            cols="12"
            class="text-center mt-3"
          >
            <!-- <strong>…………………………({{ $t('bylaws_pv.month') }})</strong> -->
            <strong>{{ currentMonth }} {{ currentYear }}</strong>
          </b-col>
        </b-row>

        <hr class="my-3">

        <div>
          <b-row>
            <b-col
              cols="12"
              class="mt-2"
            >
              <p>"L'an deux mil vingt <span class="d-inline-block"><b-form-input
                  class=""
                  type="number"
                  placeholder="annee"
                /></span>, et du <span class="d-inline-block"><b-form-input
                  class=""
                  type="number"
                /></span> au <span class="d-inline-block"><b-form-input
                  class=""
                  type="number"
                /></span>, a été organisé sur <span class="d-inline-block"><b-form-input
                  class=""
                  placeholder="périmètre géographique de l’intervention"
                  type="text"
                /></span>, le contrôle technique
                des
                établissements d'hebergement touristiques dit contrôle initial par une équipe de <strong>l'Organe technique</strong>.<br>
                Cette opération a porté sur  <strong>{{ totalRequests }} </strong>demandes d’autorisation d'exploitation dont les
                dossiers ont été jugés acceptables par la <strong>Ministère du Tourisme de la Culture et des Arts (MTCA)</strong> conformément aux dispositions en vigueur.
              </p>

              <h4 class="ml-2 mt-2">
                I - {{ $t('bylaws_pv.squad_table.section_title') }}
              </h4>

              <p>Au titre de ces travaux, il a été constitué <strong>{{ totalVisiteCounts }} </strong> équipes de  <strong>{{ totalAgents }} </strong> agents chacune.
                Lesdites équipes sont composées et sont intervenues dans les zones respectives tel que suit : </p>
            </b-col>

            <b-col
              cols="12"
              class="py-2"
            >
              <table class="table table-bordered">
                <thead class="head_bg">
                  <tr>
                    <th>{{ $t('bylaws_pv.squad_table.ref') }}</th>
                    <th>{{ $t('bylaws_pv.squad_table.fullname') }}</th>
                    <th>{{ $t('bylaws_pv.squad_table.request_title') }}</th>
                    <th>{{ $t('bylaws_pv.squad_table.location') }}</th>
                  </tr>

                </thead>
                <tbody>
                  <tr
                    v-for="(visit ,indexV) in listVisits"
                    :key="indexV"
                  >
                    <td>{{ visit.reference }}</td>
                    <td>
                      <ul
                        v-for="(visitor ,index) in visit.visas"
                        :key="index"
                      >
                        <li>{{ visitor.user_fullname }}</li>

                      </ul>

                    </td>
                    <td>
                      {{ $t(
                        getTypeDemandebyCode(visit.code_demande)) }}
                    </td>
                    <td>
                      {{ visit.departement }},
                      {{ visit.commune }},
                      {{ visit.arrondissement }}
                    </td>

                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col cols="12">
              <p>Au total, <strong>{{ totalRequests }}</strong> établissements d'hebergement touristiques ont été effectivement
                visités.
                Au terme des opérations, plusieurs observations ou constats récurrents ont été faits de façon
                générale
                et consignés dans le tableau qui suit :</p>
              <h4 class="ml-2 mt-2">
                II - {{ $t('bylaws_pv.recap_obsevation.section_title') }}
              </h4>
            </b-col>
            <b-col
              cols="12"
              class="py-2"
            >
              <table class="table table-bordered">
                <thead class="head_bg">
                  <tr class="text-center">
                    <th>{{ $t('bylaws_pv.recap_obsevation.observations') }}</th>

                  </tr>

                </thead>
                <tbody>
                  <tr>
                    <td>
                      <vue-editor :editor-toolbar="customToolbar" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>

            <b-col cols="12">
              <p>A l’issue des opérations les conclusions, il a été soumis par les agents d’inspection instruits
                <strong>{{ listVisits.length }}</strong>
                Procès-verbaux dont les copies sont jointes au présent rapport général. Tenant compte des constats
                effectués et approuvés par <span class="d-inline-block"><b-form-input
                  class=""
                  type="text"
                  placeholder="nom de la structure"
                /></span>, il a été émis un avis technique par
                établissement demandeur d’autorisation ainsi qu’il suit :</span> </p>
            </b-col>

            <b-col
              cols="12"
              class="py-2"
            >
              <h4 class="text-center">
                {{ $t('bylaws_pv.recap_obsevation.reporting_table.title') }}
              </h4>
              <table class="table table-bordered">
                <thead class="head_bg">
                  <tr>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.ref') }}</th>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.raison_social') }}</th>
                    <th>1- 20</th>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.more_info') }}</th>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.review') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr />
                </tbody>
              </table>
            </b-col>

            <b-col cols="12">
              <h4 class="ml-2 mt-2">
                III - {{ $t('bylaws_pv.decisions.section_title') }}
              </h4>
              <p>Au regard des constats effectués dans chaque établissement, <span class="d-inline-block"><b-form-input
                class=""
                type="text"
                placeholder="nom de la structure"
              /></span> est
                parvenue à trois (03) types d’avis à savoir : </p>
              <ul>
                <li> <strong>{{ requestFavorables.length }} </strong> Avis favorable sans réserve (63) ;</li>
                <li> <strong>{{ requestFavorablesWithConditions.length }} </strong> Avis favorable avec réserve (132) ;</li>
                <li> <strong> {{ requestUnFavorables.length }} </strong> Avis défavorable </li>
              </ul>
              <p>Pour cause de difficultés de localisation, le contrôle <span class="d-inline-block"><b-form-input
                class=""
                type="text"
                placeholder="type de contrôle : initial,
                classement, inopiné"
              /></span> de <span class="d-inline-block"><b-form-input
                class=""
                type="number"
                placeholder="nombre"
              /></span> établissements d’hébergement n’a être effectué.</p>
              <h5 class="ml-2">
                A - {{ $t('bylaws_pv.decisions.technical_review') }}
              </h5>
              <p>Les avis techniques se présentent selon le département concerné comme suit :</p>
              <h6 class="ml-2">
                1 - {{ $t('bylaws_pv.decisions.cfsr') }}
              </h6>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                      <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                    </tr>
                  </thead>
                  <tbody v-if="requestFavorables.length == 0">
                    <tr>
                      <td colspan="3">
                        Aucune donnée
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr
                      v-for="(request ,indexR) in requestFavorables"
                      :key="indexR"
                    >
                      <template v-for="(visit,indexV) in request.visits">
                        <td>{{ visit.reference }}</td>
                        <td>{{ request.data && request.data.raison_sociale }}</td>
                        <td>{{ visit.created_at }}</td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h6 class="ml-2">
                2 - {{ $t('bylaws_pv.decisions.cfar') }}
              </h6>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                      <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                    </tr>
                  </thead>
                  <tbody v-if="requestFavorablesWithConditions.length == 0">
                    <tr>
                      <td colspan="3">
                        Aucune donnée
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr
                      v-for="(request ,indexR) in requestFavorablesWithConditions"
                      :key="indexR"
                    >
                      <template v-for="(visit,indexV) in request.visits">
                        <td>{{ visit.reference }}</td>
                        <td>{{ request.data && request.data.raison_sociale }}</td>
                        <td>{{ visit.created_at }}</td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h6 class="ml-2">
                3 - {{ $t('bylaws_pv.decisions.cd') }}
              </h6>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                      <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                    </tr>
                  </thead>
                  <tbody v-if="requestUnFavorables.length == 0">
                    <tr>
                      <td colspan="3">
                        Aucune donnée
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr
                      v-for="(request ,indexR) in requestUnFavorables"
                      :key="indexR"
                    >
                      <template v-for="(visit,indexV) in request.visits">
                        <td>{{ visit.reference }}</td>
                        <td>{{ request.data && request.data.raison_sociale }}</td>
                        <td>{{ visit.created_at }}</td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h5 class="ml-2">
                B - {{ $t('bylaws_pv.decisions.recommandations.section_title') }}
              </h5>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.recommand') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.delay') }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="requestFavorablesWithConditions.length == 0">
                    <tr>
                      <td colspan="3">
                        Aucune donnée
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr
                      v-for="(request ,indexR) in requestFavorablesWithConditions"
                      :key="indexR"
                    >
                      <template v-for="(visit,indexV) in request.visits">
                        <td>{{ visit.reference }}</td>
                        <td>{{ request.data && request.data.raison_sociale }}</td>
                        <td>  <vue-editor :editor-toolbar="customToolbar" /></td>
                        <td>{{ visit.created_at }}</td>
                      </template>
                    </tr>
                  </tbody>

                </table>
              </div>

              <h5 class="ml-2">
                C - {{ $t('bylaws_pv.decisions.motivation.section_title') }}
              </h5>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.motivation.table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.motivation.table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.motivation.rejet_motivation') }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="requestUnFavorables.length == 0">
                    <tr>
                      <td colspan="3">
                        Aucune donnée
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr
                      v-for="(request ,indexR) in requestUnFavorables"
                      :key="indexR"
                    >
                      <template v-for="(visit,indexV) in request.visits">
                        <td>{{ visit.reference }}</td>
                        <td>{{ request.data && request.data.raison_sociale }}</td>
                        <td>                        <vue-editor :editor-toolbar="customToolbar" />
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>

            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
    <vue-html2pdf
      v-show="false"
      ref="printablePvUeh"
      class="border rounded"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :manual-pagination="true"
      :filename="``"
      :pdf-quality="2"
      :pdf-margin="0"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      :paginate-elements-by-height="1000"
    >
      <section slot="pdf-content">
        <!-- pv -->
        <section class="pdf-item">
          <div class="px-4 py-3">

            <div class="text-center">
              <b-row>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.rb') }}</h5>
                  </p>
                </b-col>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.mtca') }}</h5>
                  </p>
                </b-col>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.ddt') }}</h5>
                  </p>

                </b-col>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.bt') }}</h5>
                  </p>

                </b-col>

                <b-col class="intro-page">
                  <h4>
                    {{ $t('bylaws_pv.title_ueh') }}
                  </h4>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col
                cols="12"
                class=""
              >
                <span class="text-underlined"> {{ $t('bylaws_pv.ref_head') }} </span> :
                <ul class="mt-1">

                  <li> {{ $t('bylaws_pv.ref_elem1') }}</li>
                  <li> {{ $t('bylaws_pv.ref_elem2') }}…</li>

                </ul>
              </b-col>

              <b-col
                cols="12"
                class="text-center mt-3"
              >
                <strong>…………………………({{ $t('bylaws_pv.month') }})</strong>
              </b-col>
            </b-row>

            <span class="html2pdf__page-break" />

            <div>
              <b-row>
                <b-col
                  cols="12"
                  class="mt-3"
                >
                  <p>L'an deux mil vingt ………., et du …………… au ………………….., a été organisé sur …………………………. (périmètre
                    géographique de l’intervention : département, commune, arrondissement, etc…), le contrôle technique
                    des
                    établissements de ………………………… dit contrôle initial par une équipe de ………………………………..(nom
                    structure).<br>
                    Cette opération a porté sur ………………. (nombre total) demandes d’autorisation d'exploitation dont les
                    dossiers ont été jugés acceptables par la ………………………………… (structure ayant examiné la conformité des
                    pièces) conformément aux dispositions en vigueur.
                  </p>

                  <h4 class="ml-2 mt-2">
                    I - {{ $t('bylaws_pv.squad_table.section_title') }}
                  </h4>

                  <p>Au titre de ces travaux, il a été constitué ……………(nombre) équipes de …………….(nombre) agents chacune.
                    Lesdites équipes sont composées et sont intervenues dans les zones respectives tel que suit : </p>
                </b-col>

                <b-col
                  cols="12"
                  class="py-2"
                >
                  <table class="table table-bordered">
                    <thead class="head_bg">
                      <tr>
                        <th>{{ $t('bylaws_pv.squad_table.ref') }}</th>
                        <th>{{ $t('bylaws_pv.squad_table.fullname') }}</th>
                        <th>{{ $t('bylaws_pv.squad_table.request_title') }}</th>
                        <th>{{ $t('bylaws_pv.squad_table.location') }}</th>
                      </tr>

                    </thead>
                    <tbody>
                      <tr />
                    </tbody>
                  </table>
                </b-col>
                <b-col cols="12">
                  <p>Au total, ………………..(nombre) établissements ……………………… (nature de métier) ont été effectivement
                    visités.
                    Au terme des opérations, plusieurs observations ou constats récurrents ont été faits de façon
                    générale
                    et consignés dans le tableau qui suit :</p>
                  <h4 class="ml-2 mt-2">
                    II - {{ $t('bylaws_pv.recap_obsevation.section_title') }}
                  </h4>
                </b-col>
                <b-col
                  cols="12"
                  class="py-2"
                >
                  <table class="table table-bordered">
                    <thead class="head_bg">
                      <tr class="text-center">
                        <th>{{ $t('bylaws_pv.recap_obsevation.observations') }}</th>

                      </tr>

                    </thead>
                    <tbody>
                      <tr />
                    </tbody>
                  </table>
                </b-col>

                <b-col cols="12">
                  <p>A l’issue des opérations les conclusions, il a été soumis par les agents d’inspection instruits
                    ……………….
                    Procès-verbaux dont les copies sont jointes au présent rapport général. Tenant compte des constats
                    effectués et approuvés par …………………………. (nom de la structure), il a été émis un avis technique par
                    établissement demandeur d’autorisation ainsi qu’il suit : </p>
                </b-col>

                <b-col
                  cols="12"
                  class="py-2"
                >
                  <h4 class="text-center">
                    {{ $t('bylaws_pv.recap_obsevation.reporting_table.title') }}
                  </h4>
                  <table class="table table-bordered">
                    <thead class="head_bg">
                      <tr>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.ref') }}</th>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.raison_social') }}</th>
                        <th>1- 20</th>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.more_info') }}</th>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.review') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr />
                    </tbody>
                  </table>
                </b-col>

                <b-col cols="12">
                  <h4 class="ml-2 mt-2">
                    III - {{ $t('bylaws_pv.decisions.section_title') }}
                  </h4>
                  <p>Au regard des constats effectués dans chaque établissement …………………..(nom de la structure) est
                    parvenue à trois (03) types d’avis à savoir : </p>
                  <ul>
                    <li>…………………. (nombre) Avis favorable sans réserve (63) ;</li>
                    <li>…………………. (nombre) Avis favorable avec réserve (132) ;</li>
                    <li>…………………. (nombre) Avis défavorable </li>
                  </ul>
                  <p>Pour cause de difficultés de localisation, le contrôle ………………… (type de contrôle : initial,
                    classement, inopiné) de …………………. (nombre) établissements d’hébergement n’a être effectué.</p>
                  <h5 class="ml-2">
                    A - {{ $t('bylaws_pv.decisions.technical_review') }}
                  </h5>
                  <p>Les avis techniques se présentent selon le département concerné comme suit :</p>
                  <h6 class="ml-2">
                    1 - {{ $t('bylaws_pv.decisions.cfsr') }}
                  </h6>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                          <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h6 class="ml-2">
                    2 - {{ $t('bylaws_pv.decisions.cfar') }}
                  </h6>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                          <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h6 class="ml-2">
                    3 - {{ $t('bylaws_pv.decisions.cd') }}
                  </h6>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                          <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h5 class="ml-2">
                    B - {{ $t('bylaws_pv.decisions.recommandations.section_title') }}
                  </h5>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.recommand') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.delay') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h5 class="ml-2">
                    C - {{ $t('bylaws_pv.decisions.motivation.section_title') }}
                  </h5>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.motivation.table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.motivation.table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.motivation.rejet_motivation') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                </b-col>
              </b-row>
            </div>
          </div>
        </section>

        <!-- <hr class="invoice-spacing" /> -->
      </section>
    </vue-html2pdf>

    <!-- Pv uer -->

    <b-modal
      id="modal-pv-uer"
      ref="modal-pv-uer"
      ok-only
      content-class="shadow"
      :title="$t('bylaws_pv.modal_title')"
      :ok-title="$t('form_details.close_modal')"
      centered
      size="xl"
      no-close-on-backdrop
      @ok=""
    >
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          size="sm"
          @click="downloadPDF('modal-pv-uer')"
        >
          <b-spinner
            v-if="isDownloading"
            small
          />
          {{ $t('form_details.download_pv') }}
        </b-button>
      </div>
      <div class="px-4 py-3">

        <div class="text-center">
          <b-row>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.rb') }}</h5>
              </p>
            </b-col>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.mtca') }}</h5>
              </p>
            </b-col>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.ddt') }}</h5>
              </p>

            </b-col>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.bt') }}</h5>
              </p>

            </b-col>

            <b-col class="intro-page">
              <h4>
                {{ $t('bylaws_pv.title_uer') }}
              </h4>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col
            cols="12"
            class=""
          >
            <span class="text-underlined"> {{ $t('bylaws_pv.ref_head') }} </span> :
            <ul class="mt-1">

              <li>{{ $t('bylaws_pv.ref_elem1') }}</li>
              <li>{{ $t('bylaws_pv.ref_elem2') }}…</li>

            </ul>
          </b-col>

          <b-col
            cols="12"
            class="text-center mt-3"
          >
            <strong>…………………………({{ $t('bylaws_pv.month') }})</strong>
          </b-col>
        </b-row>

        <hr class="py-3">

        <div>
          <b-row>
            <b-col
              cols="12"
              class="mt-2"
            >
              <p>L'an deux mil vingt ………., et du …………… au ………………….., a été organisé sur …………………………. (périmètre
                géographique de l’intervention : département, commune, arrondissement, etc…), le contrôle technique
                des
                établissements de ………………………… dit contrôle initial par une équipe de ………………………………..(nom
                structure).<br>
                Cette opération a porté sur ………………. (nombre total) demandes d’autorisation d'exploitation dont les
                dossiers ont été jugés acceptables par la ………………………………… (structure ayant examiné la conformité des
                pièces) conformément aux dispositions en vigueur.
              </p>

              <h4 class="ml-2 mt-2">
                I - {{ $t('bylaws_pv.squad_table.section_title') }}
              </h4>

              <p>Au titre de ces travaux, il a été constitué ……………(nombre) équipes de …………….(nombre) agents chacune.
                Lesdites équipes sont composées et sont intervenues dans les zones respectives tel que suit : </p>
            </b-col>

            <b-col
              cols="12"
              class="py-2"
            >
              <table class="table table-bordered">
                <thead class="head_bg">
                  <tr>
                    <th>{{ $t('bylaws_pv.squad_table.ref') }}</th>
                    <th>{{ $t('bylaws_pv.squad_table.fullname') }}</th>
                    <th>{{ $t('bylaws_pv.squad_table.request_title') }}</th>
                    <th>{{ $t('bylaws_pv.squad_table.location') }}</th>
                  </tr>

                </thead>
                <tbody>
                  <tr />
                </tbody>
              </table>
            </b-col>
            <b-col cols="12">
              <p>Au total, ………………..(nombre) établissements ……………………… (nature de métier) ont été effectivement
                visités.
                Au terme des opérations, plusieurs observations ou constats récurrents ont été faits de façon
                générale
                et consignés dans le tableau qui suit :</p>
              <h4 class="ml-2 mt-2">
                II - {{ $t('bylaws_pv.recap_obsevation.section_title') }}
              </h4>
            </b-col>
            <b-col
              cols="12"
              class="py-2"
            >
              <table class="table table-bordered">
                <thead class="head_bg">
                  <tr class="text-center">
                    <th>{{ $t('bylaws_pv.recap_obsevation.observations') }}</th>

                  </tr>

                </thead>
                <tbody>
                  <tr />
                </tbody>
              </table>
            </b-col>

            <b-col cols="12">
              <p>A l’issue des opérations les conclusions, il a été soumis par les agents d’inspection instruits
                ……………….
                Procès-verbaux dont les copies sont jointes au présent rapport général. Tenant compte des constats
                effectués et approuvés par …………………………. (nom de la structure), il a été émis un avis technique par
                établissement demandeur d’autorisation ainsi qu’il suit : </p>
            </b-col>

            <b-col
              cols="12"
              class="py-2"
            >
              <h4 class="text-center">
                {{ $t('bylaws_pv.recap_obsevation.reporting_table.title') }}
              </h4>
              <table class="table table-bordered">
                <thead class="head_bg">
                  <tr>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.ref') }}</th>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.raison_social') }}</th>
                    <th>1- 20</th>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.more_info') }}</th>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.review') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr />
                </tbody>
              </table>
            </b-col>

            <b-col cols="12">
              <h4 class="ml-2 mt-2">
                III - {{ $t('bylaws_pv.decisions.section_title') }}
              </h4>
              <p>Au regard des constats effectués dans chaque établissement …………………..(nom de la structure) est
                parvenue à trois (03) types d’avis à savoir : </p>
              <ul>
                <li>…………………. (nombre) Avis favorable sans réserve (63) ;</li>
                <li>…………………. (nombre) Avis favorable avec réserve (132) ;</li>
                <li>…………………. (nombre) Avis défavorable </li>
              </ul>
              <p>Pour cause de difficultés de localisation, le contrôle ………………… (type de contrôle : initial,
                classement, inopiné) de …………………. (nombre) établissements d’hébergement n’a être effectué.</p>
              <h5 class="ml-2">
                A - {{ $t('bylaws_pv.decisions.technical_review') }}
              </h5>
              <p>Les avis techniques se présentent selon le département concerné comme suit :</p>
              <h6 class="ml-2">
                1 - {{ $t('bylaws_pv.decisions.cfsr') }}
              </h6>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                      <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

              <h6 class="ml-2">
                2 - {{ $t('bylaws_pv.decisions.cfar') }}
              </h6>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                      <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

              <h6 class="ml-2">
                3 - {{ $t('bylaws_pv.decisions.cd') }}
              </h6>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                      <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

              <h5 class="ml-2">
                B - {{ $t('bylaws_pv.decisions.recommandations.section_title') }}
              </h5>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.recommand') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.delay') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

              <h5 class="ml-2">
                C - {{ $t('bylaws_pv.decisions.motivation.section_title') }}
              </h5>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.motivation.table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.motivation.table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.motivation.rejet_motivation') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
    <vue-html2pdf
      v-show="false"
      ref="printablePvUer"
      class="border rounded"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :manual-pagination="true"
      :filename="``"
      :pdf-quality="2"
      :pdf-margin="0"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      :paginate-elements-by-height="1000"
    >
      <section slot="pdf-content">
        <!-- pv -->
        <section class="pdf-item">
          <div class="px-4 py-3">

            <div class="text-center">
              <b-row>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.rb') }}</h5>
                  </p>
                </b-col>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.mtca') }}</h5>
                  </p>
                </b-col>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.ddt') }}</h5>
                  </p>

                </b-col>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.bt') }}</h5>
                  </p>

                </b-col>

                <b-col class="intro-page">
                  <h4>
                    {{ $t('bylaws_pv.title_uer') }}
                  </h4>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col
                cols="12"
                class=""
              >
                <span class="text-underlined"> {{ $t('bylaws_pv.ref_head') }} </span> :
                <ul class="mt-1">

                  <li>{{ $t('bylaws_pv.ref_elem1') }}</li>
                  <li>{{ $t('bylaws_pv.ref_elem2') }}…</li>

                </ul>
              </b-col>

              <b-col
                cols="12"
                class="text-center mt-3"
              >
                <strong>…………………………({{ $t('bylaws_pv.month') }})</strong>
              </b-col>
            </b-row>

            <span class="html2pdf__page-break" />

            <div>
              <b-row>
                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <p>L'an deux mil vingt ………., et du …………… au ………………….., a été organisé sur …………………………. (périmètre
                    géographique de l’intervention : département, commune, arrondissement, etc…), le contrôle technique
                    des
                    établissements de ………………………… dit contrôle initial par une équipe de ………………………………..(nom
                    structure).<br>
                    Cette opération a porté sur ………………. (nombre total) demandes d’autorisation d'exploitation dont les
                    dossiers ont été jugés acceptables par la ………………………………… (structure ayant examiné la conformité des
                    pièces) conformément aux dispositions en vigueur.
                  </p>

                  <h4 class="ml-2 mt-2">
                    I - {{ $t('bylaws_pv.squad_table.section_title') }}
                  </h4>

                  <p>Au titre de ces travaux, il a été constitué ……………(nombre) équipes de …………….(nombre) agents chacune.
                    Lesdites équipes sont composées et sont intervenues dans les zones respectives tel que suit : </p>
                </b-col>

                <b-col
                  cols="12"
                  class="py-2"
                >
                  <table class="table table-bordered">
                    <thead class="head_bg">
                      <tr>
                        <th>{{ $t('bylaws_pv.squad_table.ref') }}</th>
                        <th>{{ $t('bylaws_pv.squad_table.fullname') }}</th>
                        <th>{{ $t('bylaws_pv.squad_table.request_title') }}</th>
                        <th>{{ $t('bylaws_pv.squad_table.location') }}</th>
                      </tr>

                    </thead>
                    <tbody>
                      <tr />
                    </tbody>
                  </table>
                </b-col>
                <b-col cols="12">
                  <p>Au total, ………………..(nombre) établissements ……………………… (nature de métier) ont été effectivement
                    visités.
                    Au terme des opérations, plusieurs observations ou constats récurrents ont été faits de façon
                    générale
                    et consignés dans le tableau qui suit :</p>
                  <h4 class="ml-2 mt-2">
                    II - {{ $t('bylaws_pv.recap_obsevation.section_title') }}
                  </h4>
                </b-col>
                <b-col
                  cols="12"
                  class="py-2"
                >
                  <table class="table table-bordered">
                    <thead class="head_bg">
                      <tr class="text-center">
                        <th>{{ $t('bylaws_pv.recap_obsevation.observations') }}</th>

                      </tr>

                    </thead>
                    <tbody>
                      <tr />
                    </tbody>
                  </table>
                </b-col>

                <b-col cols="12">
                  <p>A l’issue des opérations les conclusions, il a été soumis par les agents d’inspection instruits
                    ……………….
                    Procès-verbaux dont les copies sont jointes au présent rapport général. Tenant compte des constats
                    effectués et approuvés par …………………………. (nom de la structure), il a été émis un avis technique par
                    établissement demandeur d’autorisation ainsi qu’il suit : </p>
                </b-col>

                <b-col
                  cols="12"
                  class="py-2"
                >
                  <h4 class="text-center">
                    {{ $t('bylaws_pv.recap_obsevation.reporting_table.title') }}
                  </h4>
                  <table class="table table-bordered">
                    <thead class="head_bg">
                      <tr>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.ref') }}</th>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.raison_social') }}</th>
                        <th>1- 20</th>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.more_info') }}</th>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.review') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr />
                    </tbody>
                  </table>
                </b-col>

                <b-col cols="12">
                  <h4 class="ml-2 mt-2">
                    III - {{ $t('bylaws_pv.decisions.section_title') }}
                  </h4>
                  <p>Au regard des constats effectués dans chaque établissement …………………..(nom de la structure) est
                    parvenue à trois (03) types d’avis à savoir : </p>
                  <ul>
                    <li>…………………. (nombre) Avis favorable sans réserve (63) ;</li>
                    <li>…………………. (nombre) Avis favorable avec réserve (132) ;</li>
                    <li>…………………. (nombre) Avis défavorable </li>
                  </ul>
                  <p>Pour cause de difficultés de localisation, le contrôle ………………… (type de contrôle : initial,
                    classement, inopiné) de …………………. (nombre) établissements d’hébergement n’a être effectué.</p>
                  <h5 class="ml-2">
                    A - {{ $t('bylaws_pv.decisions.technical_review') }}
                  </h5>
                  <p>Les avis techniques se présentent selon le département concerné comme suit :</p>
                  <h6 class="ml-2">
                    1 - {{ $t('bylaws_pv.decisions.cfsr') }}
                  </h6>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                          <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h6 class="ml-2">
                    2 - {{ $t('bylaws_pv.decisions.cfar') }}
                  </h6>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                          <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h6 class="ml-2">
                    3 - {{ $t('bylaws_pv.decisions.cd') }}
                  </h6>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                          <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h5 class="ml-2">
                    B - {{ $t('bylaws_pv.decisions.recommandations.section_title') }}
                  </h5>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.recommand') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.delay') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h5 class="ml-2">
                    C - {{ $t('bylaws_pv.decisions.motivation.section_title') }}
                  </h5>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.motivation.table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.motivation.table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.motivation.rejet_motivation') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                </b-col>
              </b-row>
            </div>
          </div>
        </section>

        <!-- <hr class="invoice-spacing" /> -->
      </section>
    </vue-html2pdf>
    <!-- Pv ueav -->
    <b-modal
      id="modal-pv-ueav"
      ref="modal-pv-ueav"
      ok-only
      content-class="shadow"
      :title="$t('bylaws_pv.modal_title')"
      :ok-title="$t('form_details.close_modal')"
      centered
      size="xl"
      no-close-on-backdrop
      @ok=""
    >
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          size="sm"
          @click="downloadPDF('modal-pv-ueav')"
        >
          <b-spinner
            v-if="isDownloading"
            small
          />
          {{ $t('form_details.download_pv') }}
        </b-button>
      </div>
      <div class="px-4 py-3">

        <div class="text-center">
          <b-row>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.rb') }}</h5>
              </p>
            </b-col>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.mtca') }}</h5>
              </p>
            </b-col>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.ddt') }}</h5>
              </p>

            </b-col>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.bt') }}</h5>
              </p>

            </b-col>

            <b-col class="intro-page">
              <h4>
                {{ $t('bylaws_pv.title_ueav') }}
              </h4>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col
            cols="12"
            class=""
          >
            <span class="text-underlined"> {{ $t('bylaws_pv.ref_head') }} </span> :
            <ul class="mt-1">

              <li>{{ $t('bylaws_pv.ref_elem1') }}</li>
              <li>{{ $t('bylaws_pv.ref_elem2') }}…</li>

            </ul>
          </b-col>

          <b-col
            cols="12"
            class="text-center mt-3"
          >
            <strong>…………………………({{ $t('bylaws_pv.month') }})</strong>
          </b-col>
        </b-row>

        <hr class="py-3">

        <div>
          <b-row>
            <b-col
              cols="12"
              class="mt-2"
            >
              <p>L'an deux mil vingt ………., et du …………… au ………………….., a été organisé sur …………………………. (périmètre
                géographique de l’intervention : département, commune, arrondissement, etc…), le contrôle technique
                des
                établissements de ………………………… dit contrôle initial par une équipe de ………………………………..(nom
                structure).<br>
                Cette opération a porté sur ………………. (nombre total) demandes d’autorisation d'exploitation dont les
                dossiers ont été jugés acceptables par la ………………………………… (structure ayant examiné la conformité des
                pièces) conformément aux dispositions en vigueur.
              </p>

              <h4 class="ml-2 mt-2">
                I - {{ $t('bylaws_pv.squad_table.section_title') }}
              </h4>

              <p>Au titre de ces travaux, il a été constitué ……………(nombre) équipes de …………….(nombre) agents chacune.
                Lesdites équipes sont composées et sont intervenues dans les zones respectives tel que suit : </p>
            </b-col>

            <b-col
              cols="12"
              class="py-2"
            >
              <table class="table table-bordered">
                <thead class="head_bg">
                  <tr>
                    <th>{{ $t('bylaws_pv.squad_table.ref') }}</th>
                    <th>{{ $t('bylaws_pv.squad_table.fullname') }}</th>
                    <th>{{ $t('bylaws_pv.squad_table.request_title') }}</th>
                    <th>{{ $t('bylaws_pv.squad_table.location') }}</th>
                  </tr>

                </thead>
                <tbody>
                  <tr />
                </tbody>
              </table>
            </b-col>
            <b-col cols="12">
              <p>Au total, ………………..(nombre) établissements ……………………… (nature de métier) ont été effectivement
                visités.
                Au terme des opérations, plusieurs observations ou constats récurrents ont été faits de façon
                générale
                et consignés dans le tableau qui suit :</p>
              <h4 class="ml-2 mt-2">
                II - {{ $t('bylaws_pv.recap_obsevation.section_title') }}
              </h4>
            </b-col>
            <b-col
              cols="12"
              class="py-2"
            >
              <table class="table table-bordered">
                <thead class="head_bg">
                  <tr class="text-center">
                    <th>{{ $t('bylaws_pv.recap_obsevation.observations') }}</th>

                  </tr>

                </thead>
                <tbody>
                  <tr />
                </tbody>
              </table>
            </b-col>

            <b-col cols="12">
              <p>A l’issue des opérations les conclusions, il a été soumis par les agents d’inspection instruits
                ……………….
                Procès-verbaux dont les copies sont jointes au présent rapport général. Tenant compte des constats
                effectués et approuvés par …………………………. (nom de la structure), il a été émis un avis technique par
                établissement demandeur d’autorisation ainsi qu’il suit : </p>
            </b-col>

            <b-col
              cols="12"
              class="py-2"
            >
              <h4 class="text-center">
                {{ $t('bylaws_pv.recap_obsevation.reporting_table.title') }}
              </h4>
              <table class="table table-bordered">
                <thead class="head_bg">
                  <tr>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.ref') }}</th>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.raison_social') }}</th>
                    <th>1- 20</th>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.more_info') }}</th>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.review') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr />
                </tbody>
              </table>
            </b-col>

            <b-col cols="12">
              <h4 class="ml-2 mt-2">
                III - {{ $t('bylaws_pv.decisions.section_title') }}
              </h4>
              <p>Au regard des constats effectués dans chaque établissement …………………..(nom de la structure) est
                parvenue à trois (03) types d’avis à savoir : </p>
              <ul>
                <li>…………………. (nombre) Avis favorable sans réserve (63) ;</li>
                <li>…………………. (nombre) Avis favorable avec réserve (132) ;</li>
                <li>…………………. (nombre) Avis défavorable </li>
              </ul>
              <p>Pour cause de difficultés de localisation, le contrôle ………………… (type de contrôle : initial,
                classement, inopiné) de …………………. (nombre) établissements d’hébergement n’a être effectué.</p>
              <h5 class="ml-2">
                A - {{ $t('bylaws_pv.decisions.technical_review') }}
              </h5>
              <p>Les avis techniques se présentent selon le département concerné comme suit :</p>
              <h6 class="ml-2">
                1 - {{ $t('bylaws_pv.decisions.cfsr') }}
              </h6>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                      <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

              <h6 class="ml-2">
                2 - {{ $t('bylaws_pv.decisions.cfar') }}
              </h6>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                      <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

              <h6 class="ml-2">
                3 - {{ $t('bylaws_pv.decisions.cd') }}
              </h6>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                      <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

              <h5 class="ml-2">
                B - {{ $t('bylaws_pv.decisions.recommandations.section_title') }}
              </h5>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.recommand') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.delay') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

              <h5 class="ml-2">
                C - {{ $t('bylaws_pv.decisions.motivation.section_title') }}
              </h5>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.motivation.table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.motivation.table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.motivation.rejet_motivation') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
    <vue-html2pdf
      v-show="false"
      ref="printablePvUeav"
      class="border rounded"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :manual-pagination="true"
      :filename="``"
      :pdf-quality="2"
      :pdf-margin="0"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      :paginate-elements-by-height="1000"
    >
      <section slot="pdf-content">
        <!-- pv -->
        <section class="pdf-item">
          <div class="px-4 py-3">

            <div class="text-center">
              <b-row>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.rb') }}</h5>
                  </p>
                </b-col>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.mtca') }}</h5>
                  </p>
                </b-col>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.ddt') }}</h5>
                  </p>

                </b-col>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.bt') }}</h5>
                  </p>

                </b-col>

                <b-col class="intro-page">
                  <h4>
                    {{ $t('bylaws_pv.title_ueav') }}
                  </h4>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col
                cols="12"
                class=""
              >
                <span class="text-underlined"> {{ $t('bylaws_pv.ref_head') }} </span> :
                <ul class="mt-1">

                  <li>{{ $t('bylaws_pv.ref_elem1') }}</li>
                  <li>{{ $t('bylaws_pv.ref_elem2') }}…</li>

                </ul>
              </b-col>

              <b-col
                cols="12"
                class="text-center mt-3"
              >
                <strong>…………………………({{ $t('bylaws_pv.month') }})</strong>
              </b-col>
            </b-row>

            <span class="html2pdf__page-break" />

            <div>
              <b-row>
                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <p>L'an deux mil vingt ………., et du …………… au ………………….., a été organisé sur …………………………. (périmètre
                    géographique de l’intervention : département, commune, arrondissement, etc…), le contrôle technique
                    des
                    établissements de ………………………… dit contrôle initial par une équipe de ………………………………..(nom
                    structure).<br>
                    Cette opération a porté sur ………………. (nombre total) demandes d’autorisation d'exploitation dont les
                    dossiers ont été jugés acceptables par la ………………………………… (structure ayant examiné la conformité des
                    pièces) conformément aux dispositions en vigueur.
                  </p>

                  <h4 class="ml-2 mt-2">
                    I - {{ $t('bylaws_pv.squad_table.section_title') }}
                  </h4>

                  <p>Au titre de ces travaux, il a été constitué ……………(nombre) équipes de …………….(nombre) agents chacune.
                    Lesdites équipes sont composées et sont intervenues dans les zones respectives tel que suit : </p>
                </b-col>

                <b-col
                  cols="12"
                  class="py-2"
                >
                  <table class="table table-bordered">
                    <thead class="head_bg">
                      <tr>
                        <th>{{ $t('bylaws_pv.squad_table.ref') }}</th>
                        <th>{{ $t('bylaws_pv.squad_table.fullname') }}</th>
                        <th>{{ $t('bylaws_pv.squad_table.request_title') }}</th>
                        <th>{{ $t('bylaws_pv.squad_table.location') }}</th>
                      </tr>

                    </thead>
                    <tbody>
                      <tr />
                    </tbody>
                  </table>
                </b-col>
                <b-col cols="12">
                  <p>Au total, ………………..(nombre) établissements ……………………… (nature de métier) ont été effectivement
                    visités.
                    Au terme des opérations, plusieurs observations ou constats récurrents ont été faits de façon
                    générale
                    et consignés dans le tableau qui suit :</p>
                  <h4 class="ml-2 mt-2">
                    II - {{ $t('bylaws_pv.recap_obsevation.section_title') }}
                  </h4>
                </b-col>
                <b-col
                  cols="12"
                  class="py-2"
                >
                  <table class="table table-bordered">
                    <thead class="head_bg">
                      <tr class="text-center">
                        <th>{{ $t('bylaws_pv.recap_obsevation.observations') }}</th>

                      </tr>

                    </thead>
                    <tbody>
                      <tr />
                    </tbody>
                  </table>
                </b-col>

                <b-col cols="12">
                  <p>A l’issue des opérations les conclusions, il a été soumis par les agents d’inspection instruits
                    ……………….
                    Procès-verbaux dont les copies sont jointes au présent rapport général. Tenant compte des constats
                    effectués et approuvés par …………………………. (nom de la structure), il a été émis un avis technique par
                    établissement demandeur d’autorisation ainsi qu’il suit : </p>
                </b-col>

                <b-col
                  cols="12"
                  class="py-2"
                >
                  <h4 class="text-center">
                    {{ $t('bylaws_pv.recap_obsevation.reporting_table.title') }}
                  </h4>
                  <table class="table table-bordered">
                    <thead class="head_bg">
                      <tr>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.ref') }}</th>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.raison_social') }}</th>
                        <th>1- 20</th>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.more_info') }}</th>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.review') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr />
                    </tbody>
                  </table>
                </b-col>

                <b-col cols="12">
                  <h4 class="ml-2 mt-2">
                    III - {{ $t('bylaws_pv.decisions.section_title') }}
                  </h4>
                  <p>Au regard des constats effectués dans chaque établissement …………………..(nom de la structure) est
                    parvenue à trois (03) types d’avis à savoir : </p>
                  <ul>
                    <li>…………………. (nombre) Avis favorable sans réserve (63) ;</li>
                    <li>…………………. (nombre) Avis favorable avec réserve (132) ;</li>
                    <li>…………………. (nombre) Avis défavorable </li>
                  </ul>
                  <p>Pour cause de difficultés de localisation, le contrôle ………………… (type de contrôle : initial,
                    classement, inopiné) de …………………. (nombre) établissements d’hébergement n’a être effectué.</p>
                  <h5 class="ml-2">
                    A - {{ $t('bylaws_pv.decisions.technical_review') }}
                  </h5>
                  <p>Les avis techniques se présentent selon le département concerné comme suit :</p>
                  <h6 class="ml-2">
                    1 - {{ $t('bylaws_pv.decisions.cfsr') }}
                  </h6>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                          <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h6 class="ml-2">
                    2 - {{ $t('bylaws_pv.decisions.cfar') }}
                  </h6>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                          <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h6 class="ml-2">
                    3 - {{ $t('bylaws_pv.decisions.cd') }}
                  </h6>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                          <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h5 class="ml-2">
                    B - {{ $t('bylaws_pv.decisions.recommandations.section_title') }}
                  </h5>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.recommand') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.delay') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h5 class="ml-2">
                    C - {{ $t('bylaws_pv.decisions.motivation.section_title') }}
                  </h5>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.motivation.table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.motivation.table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.motivation.rejet_motivation') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                </b-col>
              </b-row>
            </div>
          </div>
        </section>

        <!-- <hr class="invoice-spacing" /> -->
      </section>
    </vue-html2pdf>
    <!-- Pv uegt -->
    <b-modal
      id="modal-pv-uegt"
      ref="modal-pv-uegt"
      ok-only
      content-class="shadow"
      :title="$t('bylaws_pv.modal_title')"
      :ok-title="$t('form_details.close_modal')"
      centered
      size="xl"
      no-close-on-backdrop
      @ok=""
    >
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          size="sm"
          @click="downloadPDF('modal-pv-uegt')"
        >
          <b-spinner
            v-if="isDownloading"
            small
          />
          {{ $t('form_details.download_pv') }}
        </b-button>
      </div>
      <div class="px-4 py-3">

        <div class="text-center">
          <b-row>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.rb') }}</h5>
              </p>
            </b-col>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.mtca') }}</h5>
              </p>
            </b-col>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.ddt') }}</h5>
              </p>

            </b-col>
            <b-col cols="12">
              <p />
              <h5>{{ $t('bylaws_pv.bt') }}</h5>
              </p>

            </b-col>

            <b-col class="intro-page">
              <h4>
                {{ $t('bylaws_pv.title_uegt') }}
              </h4>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col
            cols="12"
            class=""
          >
            <span class="text-underlined"> {{ $t('bylaws_pv.ref_head') }} </span> :
            <ul class="mt-1">

              <li>{{ $t('bylaws_pv.ref_elem1') }}</li>
              <li>{{ $t('bylaws_pv.ref_elem2') }}…</li>

            </ul>
          </b-col>

          <b-col
            cols="12"
            class="text-center mt-3"
          >
            <strong>…………………………({{ $t('bylaws_pv.month') }})</strong>
          </b-col>
        </b-row>

        <hr class="py-3">

        <div>
          <b-row>
            <b-col
              cols="12"
              class="mt-2"
            >
              <p>L'an deux mil vingt ………., et du …………… au ………………….., a été organisé sur …………………………. (périmètre
                géographique de l’intervention : département, commune, arrondissement, etc…), le contrôle technique
                des
                établissements de ………………………… dit contrôle initial par une équipe de ………………………………..(nom
                structure).<br>
                Cette opération a porté sur ………………. (nombre total) demandes d’autorisation d'exploitation dont les
                dossiers ont été jugés acceptables par la ………………………………… (structure ayant examiné la conformité des
                pièces) conformément aux dispositions en vigueur.
              </p>

              <h4 class="ml-2 mt-2">
                I - {{ $t('bylaws_pv.squad_table.section_title') }}
              </h4>

              <p>Au titre de ces travaux, il a été constitué ……………(nombre) équipes de …………….(nombre) agents chacune.
                Lesdites équipes sont composées et sont intervenues dans les zones respectives tel que suit : </p>
            </b-col>

            <b-col
              cols="12"
              class="py-2"
            >
              <table class="table table-bordered">
                <thead class="head_bg">
                  <tr>
                    <th>{{ $t('bylaws_pv.squad_table.ref') }}</th>
                    <th>{{ $t('bylaws_pv.squad_table.fullname') }}</th>
                    <th>{{ $t('bylaws_pv.squad_table.request_title') }}</th>
                    <th>{{ $t('bylaws_pv.squad_table.location') }}</th>
                  </tr>

                </thead>
                <tbody>
                  <tr />
                </tbody>
              </table>
            </b-col>
            <b-col cols="12">
              <p>Au total, ………………..(nombre) établissements ……………………… (nature de métier) ont été effectivement
                visités.
                Au terme des opérations, plusieurs observations ou constats récurrents ont été faits de façon
                générale
                et consignés dans le tableau qui suit :</p>
              <h4 class="ml-2 mt-2">
                II - {{ $t('bylaws_pv.recap_obsevation.section_title') }}
              </h4>
            </b-col>
            <b-col
              cols="12"
              class="py-2"
            >
              <table class="table table-bordered">
                <thead class="head_bg">
                  <tr class="text-center">
                    <th>{{ $t('bylaws_pv.recap_obsevation.observations') }}</th>

                  </tr>

                </thead>
                <tbody>
                  <tr />
                </tbody>
              </table>
            </b-col>

            <b-col cols="12">
              <p>A l’issue des opérations les conclusions, il a été soumis par les agents d’inspection instruits
                ……………….
                Procès-verbaux dont les copies sont jointes au présent rapport général. Tenant compte des constats
                effectués et approuvés par …………………………. (nom de la structure), il a été émis un avis technique par
                établissement demandeur d’autorisation ainsi qu’il suit : </p>
            </b-col>

            <b-col
              cols="12"
              class="py-2"
            >
              <h4 class="text-center">
                {{ $t('bylaws_pv.recap_obsevation.reporting_table.title') }}
              </h4>
              <table class="table table-bordered">
                <thead class="head_bg">
                  <tr>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.ref') }}</th>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.raison_social') }}</th>
                    <th>1- 20</th>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.more_info') }}</th>
                    <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.review') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr />
                </tbody>
              </table>
            </b-col>

            <b-col cols="12">
              <h4 class="ml-2 mt-2">
                III - {{ $t('bylaws_pv.decisions.section_title') }}
              </h4>
              <p>Au regard des constats effectués dans chaque établissement …………………..(nom de la structure) est
                parvenue à trois (03) types d’avis à savoir : </p>
              <ul>
                <li>…………………. (nombre) Avis favorable sans réserve (63) ;</li>
                <li>…………………. (nombre) Avis favorable avec réserve (132) ;</li>
                <li>…………………. (nombre) Avis défavorable </li>
              </ul>
              <p>Pour cause de difficultés de localisation, le contrôle ………………… (type de contrôle : initial,
                classement, inopiné) de …………………. (nombre) établissements d’hébergement n’a être effectué.</p>
              <h5 class="ml-2">
                A - {{ $t('bylaws_pv.decisions.technical_review') }}
              </h5>
              <p>Les avis techniques se présentent selon le département concerné comme suit :</p>
              <h6 class="ml-2">
                1 - {{ $t('bylaws_pv.decisions.cfsr') }}
              </h6>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                      <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

              <h6 class="ml-2">
                2 - {{ $t('bylaws_pv.decisions.cfar') }}
              </h6>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                      <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

              <h6 class="ml-2">
                3 - {{ $t('bylaws_pv.decisions.cd') }}
              </h6>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                      <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

              <h5 class="ml-2">
                B - {{ $t('bylaws_pv.decisions.recommandations.section_title') }}
              </h5>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.recommand') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.recommandations.delay') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

              <h5 class="ml-2">
                C - {{ $t('bylaws_pv.decisions.motivation.section_title') }}
              </h5>
              <div class="py-2">
                <table class="table table-bordered">
                  <thead class="head_bg">
                    <tr>
                      <th>{{ $t('bylaws_pv.decisions.motivation.table_ref') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.motivation.table_raison_social') }}</th>
                      <th>{{ $t('bylaws_pv.decisions.motivation.rejet_motivation') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr />
                  </tbody>
                </table>
              </div>

            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
    <vue-html2pdf
      v-show="false"
      ref="printablePvUegt"
      class="border rounded"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :manual-pagination="true"
      :filename="``"
      :pdf-quality="2"
      :pdf-margin="0"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      :paginate-elements-by-height="1000"
    >
      <section slot="pdf-content">
        <!-- pv -->
        <section class="pdf-item">
          <div class="px-4 py-3">

            <div class="text-center">
              <b-row>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.rb') }}</h5>
                  </p>
                </b-col>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.mtca') }}</h5>
                  </p>
                </b-col>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.ddt') }}</h5>
                  </p>

                </b-col>
                <b-col cols="12">
                  <p />
                  <h5>{{ $t('bylaws_pv.bt') }}</h5>
                  </p>

                </b-col>

                <b-col class="intro-page">
                  <h4>
                    {{ $t('bylaws_pv.title_uegt') }}
                  </h4>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col
                cols="12"
                class=""
              >
                <span class="text-underlined"> {{ $t('bylaws_pv.ref_head') }} </span> :
                <ul class="mt-1">

                  <li>{{ $t('bylaws_pv.ref_elem1') }}</li>
                  <li>{{ $t('bylaws_pv.ref_elem2') }}…</li>

                </ul>
              </b-col>

              <b-col
                cols="12"
                class="text-center mt-3"
              >
                <strong>…………………………({{ $t('bylaws_pv.month') }})</strong>
              </b-col>
            </b-row>

            <span class="html2pdf__page-break" />

            <div>
              <b-row>
                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <p>L'an deux mil vingt ………., et du …………… au ………………….., a été organisé sur …………………………. (périmètre
                    géographique de l’intervention : département, commune, arrondissement, etc…), le contrôle technique
                    des
                    établissements de ………………………… dit contrôle initial par une équipe de ………………………………..(nom
                    structure).<br>
                    Cette opération a porté sur ………………. (nombre total) demandes d’autorisation d'exploitation dont les
                    dossiers ont été jugés acceptables par la ………………………………… (structure ayant examiné la conformité des
                    pièces) conformément aux dispositions en vigueur.
                  </p>

                  <h4 class="ml-2 mt-2">
                    I - {{ $t('bylaws_pv.squad_table.section_title') }}
                  </h4>

                  <p>Au titre de ces travaux, il a été constitué ……………(nombre) équipes de …………….(nombre) agents chacune.
                    Lesdites équipes sont composées et sont intervenues dans les zones respectives tel que suit : </p>
                </b-col>

                <b-col
                  cols="12"
                  class="py-2"
                >
                  <table class="table table-bordered">
                    <thead class="head_bg">
                      <tr>
                        <th>{{ $t('bylaws_pv.squad_table.ref') }}</th>
                        <th>{{ $t('bylaws_pv.squad_table.fullname') }}</th>
                        <th>{{ $t('bylaws_pv.squad_table.request_title') }}</th>
                        <th>{{ $t('bylaws_pv.squad_table.location') }}</th>
                      </tr>

                    </thead>
                    <tbody>
                      <tr />
                    </tbody>
                  </table>
                </b-col>
                <b-col cols="12">
                  <p>Au total, ………………..(nombre) établissements ……………………… (nature de métier) ont été effectivement
                    visités.
                    Au terme des opérations, plusieurs observations ou constats récurrents ont été faits de façon
                    générale
                    et consignés dans le tableau qui suit :</p>
                  <h4 class="ml-2 mt-2">
                    II - {{ $t('bylaws_pv.recap_obsevation.section_title') }}
                  </h4>
                </b-col>
                <b-col
                  cols="12"
                  class="py-2"
                >
                  <table class="table table-bordered">
                    <thead class="head_bg">
                      <tr class="text-center">
                        <th>{{ $t('bylaws_pv.recap_obsevation.observations') }}</th>

                      </tr>

                    </thead>
                    <tbody>
                      <tr />
                    </tbody>
                  </table>
                </b-col>

                <b-col cols="12">
                  <p>A l’issue des opérations les conclusions, il a été soumis par les agents d’inspection instruits
                    ……………….
                    Procès-verbaux dont les copies sont jointes au présent rapport général. Tenant compte des constats
                    effectués et approuvés par …………………………. (nom de la structure), il a été émis un avis technique par
                    établissement demandeur d’autorisation ainsi qu’il suit : </p>
                </b-col>

                <b-col
                  cols="12"
                  class="py-2"
                >
                  <h4 class="text-center">
                    {{ $t('bylaws_pv.recap_obsevation.reporting_table.title') }}
                  </h4>
                  <table class="table table-bordered">
                    <thead class="head_bg">
                      <tr>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.ref') }}</th>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.raison_social') }}</th>
                        <th>1- 20</th>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.more_info') }}</th>
                        <th>{{ $t('bylaws_pv.recap_obsevation.reporting_table.review') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr />
                    </tbody>
                  </table>
                </b-col>

                <b-col cols="12">
                  <h4 class="ml-2 mt-2">
                    III - {{ $t('bylaws_pv.decisions.section_title') }}
                  </h4>
                  <p>Au regard des constats effectués dans chaque établissement …………………..(nom de la structure) est
                    parvenue à trois (03) types d’avis à savoir : </p>
                  <ul>
                    <li>…………………. (nombre) Avis favorable sans réserve (63) ;</li>
                    <li>…………………. (nombre) Avis favorable avec réserve (132) ;</li>
                    <li>…………………. (nombre) Avis défavorable </li>
                  </ul>
                  <p>Pour cause de difficultés de localisation, le contrôle ………………… (type de contrôle : initial,
                    classement, inopiné) de …………………. (nombre) établissements d’hébergement n’a être effectué.</p>
                  <h5 class="ml-2">
                    A - {{ $t('bylaws_pv.decisions.technical_review') }}
                  </h5>
                  <p>Les avis techniques se présentent selon le département concerné comme suit :</p>
                  <h6 class="ml-2">
                    1 - {{ $t('bylaws_pv.decisions.cfsr') }}
                  </h6>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                          <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h6 class="ml-2">
                    2 - {{ $t('bylaws_pv.decisions.cfar') }}
                  </h6>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                          <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h6 class="ml-2">
                    3 - {{ $t('bylaws_pv.decisions.cd') }}
                  </h6>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.cfsr_control_date') }}</th>
                          <!-- <th>{{ $t('bylaws_pv.decisions.cfsr_technical_review') }}</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h5 class="ml-2">
                    B - {{ $t('bylaws_pv.decisions.recommandations.section_title') }}
                  </h5>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.recommand') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.recommandations.delay') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                  <h5 class="ml-2">
                    C - {{ $t('bylaws_pv.decisions.motivation.section_title') }}
                  </h5>
                  <div class="py-2">
                    <table class="table table-bordered">
                      <thead class="head_bg">
                        <tr>
                          <th>{{ $t('bylaws_pv.decisions.motivation.table_ref') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.motivation.table_raison_social') }}</th>
                          <th>{{ $t('bylaws_pv.decisions.motivation.rejet_motivation') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr />
                      </tbody>
                    </table>
                  </div>

                </b-col>
              </b-row>
            </div>
          </div>
        </section>

        <!-- <hr class="invoice-spacing" /> -->
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardImg,
  BBadge,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  VBPopover,
  BModal,
  BSpinner,
  BFormTextarea,
  VBTooltip,
  BFormDatepicker,
  BTable,
  BTableSimple,
  BTbody,
  BThead,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import Ripple from 'vue-ripple-directive'
// import flatPickr from 'vue-flatpickr-component'
// import moment from 'moment'
// import { $themeColors } from '@themeConfig'
import vSelect from 'vue-select'
import TipsModalCreateFromLastRecord from '@/components/TipsModalCreateFromLastRecord.vue'
import DateRangeWidget from '@/components/DateRangeWidget.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import utilsService from '@/services/utils/utils.service'
import VueHtml2pdf from 'vue-html2pdf'

import formulairesStoreModule from '@/store/formulaire'
import etablissementsStroreModule from '@/store/etablissements'
import usersStoreModule from '@/store/users'
import arretesStoreModule from '@/store/arretes'
import formulaireStoreModule from '@/store/formulaire'
import { VueEditor } from 'vue2-editor'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

import { buildFiltersForRequest } from '@/helpers/http-utils'
import Bails_states from './bails_states.js'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BCardImg,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    BSpinner,
    BTable,
    BTableSimple,
    BThead,
    BTbody,
    VBPopover,
    BFormTextarea,
    BModal,

    AppCollapse,
    AppCollapseItem,

    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    TipsModalCreateFromLastRecord,
    DateRangeWidget,
    PaginationComponent,
    ToastificationContent,
    vSelect,
    BFormDatepicker,
    VueHtml2pdf,
    VueEditor,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'formulaires', module: formulairesStoreModule },
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'users', module: usersStoreModule },
      { path: 'arretes', module: arretesStoreModule },
      { path: 'formulaire', module: formulaireStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    const { isEtablissementUser, isAdminEtablissement, isAgentEtablissement } =
      utilsService.currentUserUtils()

    const departementsList = utilsService.getDepartements()
    const communesList = utilsService.getCommunes()

    return {
      departementsList,
      communesList,
      isAdminEtablissement,
      isAgentEtablissement,
      isEtablissementUser,
      requiredStoreModules,
    }
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data() {
    const {
      currentUserId,
      isAdmin,
      isMtcaAdmin,
      isMtcaAgent,
      isOtAdmin,
      isOtAgent,
      isAdministrationUser,
      isAuditor,
      isOperator,
      isMinister,
    } = utilsService.currentUserUtils()
    return {
      current_sector: null,
      currentUserId,
      isAdmin,
      isMtcaAdmin,
      isMtcaAgent,
      isOtAdmin,
      isOtAgent,
      isAdministrationUser,
      isAuditor,
      isOperator,
      isMinister,

      etablissementsList: [],
      loadingAddArrete: false,

      loading: false,
      statsLoad: null,

      lastestRecordedForm: null,
      first_treatment: 0,
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        // ['image', 'link'],
      ],
      curr_arrete: {},
      arrete_data_input_date: null,
      ampliation_attributes: null,
      new_ampliation_attributes: {
        structure: null,
        copies: null,
      },
      requests_table_data: [],

      selectedForm: null,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 15,
        metaData: { from: 0, to: 0 },
      },
      currentMonth: new Date().toLocaleString('fr-FR', { month: 'long' }),
      currentYear: new Date().getFullYear(),
      demandes: [],
      listVisits: [],
      totalRequests: 0,
      totalVisiteCounts: 0,
      totalAgents: 0,
      requestUnFavorables: [],
      requestFavorablesWithConditions: [],
      requestFavorables: [],
      isLoading: false,
      file_for_vizualisation: null,
      show_vizualisation_section: false,
      isFetchingFormDetails: false,
      options_type_secteur_hebergement: [],
      options_type_secteur_restauration: [
        { title: 'Cafétaria' },
        { title: 'Restaurant-Bar' },
        { title: 'Maquis' },
        { title: 'Restaurant' },
        { title: 'Lounge' },
      ],
      pvDataUEH: {
        numAgentInspection: null,
        numPersonneRessource: null,
        year: null,
        startDate: null,
        endDate: null,
        perimetreGeo: null,
        recapObservation: null,
        recapNameStructure: null,
        deliberationNameStructure: null,
        deliberationTypeControle: null,
        deliberationCountEta: null,
        recommendations: null,
      },
      options_classement: [
        { title: 'Non classé', value: '0' },
        { title: '1 étoile', value: '1' },
        { title: '2 étoiles', value: '2' },
        { title: '3 étoiles', value: '3' },
        { title: '4 étoiles', value: '4' },
        { title: '5 étoiles', value: '5' },
      ],
      options_type_guide: [
        { title: 'Guide culturel' },
        { title: 'Guide des milieux naturels' },
      ],
      options_categorie_guide: [
        { title: 'Guide local' },
        { title: 'Guides national ou accompagnateur' },
      ],
      options_affectation_guide: [
        { title: 'Avlékété/ Grand Popo' },
        { title: 'Porto-Novo' },
        { title: 'Ganvié' },
        { title: 'Allada' },
        { title: 'Abomey/ Dassa / Kétou' },
        { title: 'Nikki/Natitingou/Boukoumbé' },
      ],

      arretes_links: [],

      months: utilsService.getListMonths(),
      years: utilsService.getListYears(),

      optionsDemandes: null,
      selectedDemandes: null,

      curr_decisions: [],
      states: null,
      current_state: null,
      next_state: null,
      loading_steps_panel: false,
      isLoading_update_state: false,
      isLoadingDownloadArrete: false,
      next_step_comment: '',
      next_step_comment_request: [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ],
      isDownloading: false,
      forPrint: false,
      current_id: '',
      adminstration_user_list: [],
      requests_by_sector: {
        ueh: [
          'UEH_autorisation_premier_classement',
          'UEH_reclassement',
          'UEH_notification',
        ],
        ueav: [
          'UEAV_licence',
          'UEAV_agrement',
          'UEAV_renew_agrement_licence',
          'UEAV_notification',
        ],
        uegt: [
          'UEGT_carte_professionnelle',
          'UEGT_renew_carte_professionnelle',
          'UEGT_changement_affectation',
          'UEGT_ajout_specialite',
        ],
        uer: [
          'UER_autorisation',
          'UER_classement',
          'UER_renew_autorisation',
          'UER_renew_classement',
          'UER_notification',
        ],
      },
      requests_by_sector_counts: {
        ueh: 0,
        ueav: 0,
        uegt: 0,
        uer: 0,
      },
    }
  },

  computed: {
    can_edit_arrete() {
      return (
        this.curr_arrete.statuses &&
        this.curr_arrete.statuses[0].name &&
        this.can_set_ministerial_decree_number(
          this.curr_arrete.statuses[0].name
        )
      )
    },
    all_requests_have_ot_decisions() {
      // control if can process
      const { demandes } = this.curr_arrete
      // Les valeurs valides pour l'attribut name
      const validNames = [
        'unfavourable_agreement',
        'favourable_agreement_with_conditions',
        'favourable_agreement',
        'classification_unfavourable_agreement',
        'classification_favourable_agreement',
        'application_inadmissible',
        'application_admissible',
        'reclassification_unfavourable_agreement',
        'reclassification_favourable_agreement',
        // 'transfert_pv_deliberation_to_minister', 'transfert_all_documentation_to_minister'
      ]
      const allValid = demandes.every((demande) => {
        if (demande.statuses && demande.statuses.length > 0) {
          const last_status = demande.statuses[0].name

          if (last_status == 'deliberation_session_check') return false
          if (last_status == 'classification_deliberation_session_check')
            return false

          const request_statuses = demande.statuses.map((status) => status.name)
          return validNames.some((element) =>
            request_statuses.includes(element)
          )
          // if( last_status == 'transfert_pv_deliberation_to_minister' || last_status == 'transfert_all_documentation_to_minister' ) return true
          // return validNames.includes(last_status)
        }
        return false
      })
      return allValid
    },
    all_requests_have_minister_decisions() {
      // control if can process
      const { demandes } = this.curr_arrete
      // Les valeurs valides pour l'attribut name
      const validNames = [
        'minister_reject_classsification',
        'minister_accept_classsification',
        'minister_unfavourable_agreement',
        'minister_favourable_agreement_with_conditions',
        'minister_favourable_agreement',
      ]
      // const validNames_classic = [
      //   'minister_unfavourable_agreement', 'minister_favourable_agreement_with_conditions', 'minister_favourable_agreement',
      // ]
      // const validNames_classification = [
      //   'minister_reject_classsification', 'minister_accept_classsification',
      // ]
      // Vérifier si chaque demande a son premier status avec un name valide
      const allValid = demandes.every((demande) => {
        if (demande.statuses && demande.statuses.length > 0) {
          const request_status = demande.statuses.map((status) => status.name)
          return validNames.some((element) => request_status.includes(element))
          // const last_status = demande.statuses[0].name
          // return validNames.includes(last_status)
        }
        // Retourner false si la demande n'a pas de statuses
        return false
      })
      return allValid
    },
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        this.states = Bails_states // this.states = this.selectedForm.process_steps;
        this.load_requests()
        this.init()
      },
    },
    'paginationData.currentPage': {
      immediate: false,
      deep: true,
      handler(val, old) {
        this.getListArretes()
      },
    },
    'paginationData.perPage': {
      immediate: false,
      deep: true,
      handler(val, old) {
        this.getListArretes()
      },
    },
    arrete_data_input_date: {
      immediate: false,
      handler(val, old) {
        this.first_treatment += 1
        if (this.first_treatment == 1) return
        const date_to_backend_format = this.replaceDashBySlash(
          this.reverseDate(this.arrete_data_input_date, '-')
        )
        console.log('date_to_backend_format::: ', date_to_backend_format);
        this.curr_arrete.arrete_data.date = date_to_backend_format
        this.update_arrete('arrete_data')
        this.first_treatment = true
      },
    },
  },

  beforeDestroy() {
    // unregisterStoreModule(this.requiredStoreModules);
  },

  mounted() {},

  methods: {
    ...mapActions('formulaires', {
      action_searchFormulaires: 'searchFormulaires',
    }),
    ...mapActions('arretes', {
      action_fetchArretes: 'fetchArretes',
      actions_searchArretes: 'searchArretes',
      actions_addArrete: 'addArrete',
      actions_getArrete: 'getArrete',
      actions_updateArrete: 'updateArrete',
      actions_getArreteDownloadArrete: 'getArreteDownloadArrete',
    }),
    ...mapActions('formulaire', {
      action_getFormulaire: 'getFormulaire',
      action_updateFormulaire: 'updateFormulaire',
      action_updateFormulaireFormData: 'updateFormulaireFormData',
      action_downloadBilan: 'downloadBilan',
      action_downloadQa: 'downloadQaEvaluation',
      action_downloadResult: 'downloadEvaluationResult',
    }),
    ...mapActions('users', {
      action_searchUsers: 'searchUsers',
    }),
    ...mapActions('etablissements', {
      action_searchTypeEtablissement: 'searchTypeEtablissement',
    }),
    // ...mapActions("params", { action_fetchTypesRoles: "fetchTypesRoles" }),
    // ----------------------------------------------------------------------------------------
    init() {
      const params = {
        roles: ['TA', 'TAD', 'AUAT', 'UAT', 'AUD'],
      }
      this.action_searchUsers(params)
        .then(async (response) => {
          if (response.status === 200) {
            response.data.data.forEach((user) => {
              user.label = `${user.lastname} ${user.firstname}, ${user.email}`
            })
            // return response.data.data
            this.adminstration_user_list = response.data.data
            // console.log('this.adminstration_user_list::: 🟢🟠🔴', this.adminstration_user_list)
          }
        })
        .catch((err) => {
          console.log('err::: ', err)
        })
    },
    showPv(modal) {
      this.$refs[modal].show()
    },
    // formatPhrase(data) {
    //   const formatDate = (dateString) => {
    //     const parts = dateString.split('-')
    //     return new Date(`${parts[2]}-${parts[1]}-${parts[0]} ${parts[3]}`)
    //   }
    //   const firstDate = formatDate(data[0].date)
    //   const lastDate = data[data.length - 1].date
    //   console.log('hadjha', data[0].date, data.length - 1)
    //   return
    // },
    async downloadPDF(modal) {
      this.isDownloading = true
      this.forPrint = true
      if (modal == 'modal-pv-ueh') {
        await this.$refs.printablePvUeh.generatePdf()
        this.$refs.printablePvUeh.$once('hasDownloaded', () => {
          this.isDownloading = false
          if (modal) {
            this.$refs[modal].hide()
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Document téléchargé avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.forPrint = false
        })
      }
      if (modal == 'modal-pv-uegt') {
        await this.$refs.printablePvUegt.generatePdf()
        this.$refs.printablePvUegt.$once('hasDownloaded', () => {
          this.isDownloading = false
          if (modal) {
            this.$refs[modal].hide()
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Document téléchargé avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.forPrint = false
        })
      }
      if (modal == 'modal-pv-ueav') {
        await this.$refs.printablePvUeav.generatePdf()
        this.$refs.printablePvUeav.$once('hasDownloaded', () => {
          this.isDownloading = false
          if (modal) {
            this.$refs[modal].hide()
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Document téléchargé avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.forPrint = false
        })
      }
      if (modal == 'modal-pv-uer') {
        await this.$refs.printablePvUer.generatePdf()
        this.$refs.printablePvUer.$once('hasDownloaded', () => {
          this.isDownloading = false
          if (modal) {
            this.$refs[modal].hide()
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Document téléchargé avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.forPrint = false
        })
      }
    },
    load_requests() {
      this.isLoading = true
      this.current_id = this.$route.params.id
      this.actions_getArrete(this.current_id)
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false
            this.requests_table_data = []
            this.curr_arrete = res.data.data
            // console.log(
            //   'this.curr_arrete::: ⭕️',
            //   this.curr_arrete,
            //   this.curr_arrete.arrete_data
            // )
            const incoming_date = this.curr_arrete.arrete_data.date
            this.arrete_data_input_date = this.replaceSlashByDash(
              this.reverseDate(incoming_date, '/')
            )
            this.download_arrete()
            this.load_ampliation()

            this.demandes = this.curr_arrete.demandes

            // console.log('demandes:', this.demandes, this.demandes)
            this.pv_data_regroup(this.demandes)

            this.curr_arrete.demandes.forEach((demande, demande_index) => {
              // console.log('demande::: ⭕️⭕️', demande)
              const { code_demande } = demande.data
              const statuses_name = demande.statuses.map(
                (status) => status.name
              )

              const filteredArray = statuses_name.filter(
                (item) => item == 'classification_deliberation_session_check'
              )

              if (demande.type_demande) {
                const slug = utilsService.getSlugByCodeDemande(code_demande)
                demande.slug = slug

                const selectedForm =
                  utilsService.getFormulaireByCodeClient(slug)
                // console.log( 'selectedForm::: ⭕️⭕️⭕️⭕️⭕️', selectedForm)
                if (demande_index == 0)
                  this.current_sector = selectedForm.sector
                demande.selectedForm = selectedForm
                switch (code_demande) {
                  case 'UEH_autorisation_premier_classement':
                    this.requests_by_sector_counts.ueh += 1
                    break
                  case 'UEH_reclassement':
                    this.requests_by_sector_counts.ueh += 1
                    break
                  case 'UEH_notification':
                    this.requests_by_sector_counts.ueh += 1
                    break

                  case 'UEAV_licence':
                    this.requests_by_sector_counts.ueav += 1
                    break
                  case 'UEAV_agrement':
                    this.requests_by_sector_counts.ueav += 1
                    break
                  case 'UEAV_renew_agrement_licence':
                    this.requests_by_sector_counts.ueav += 1
                    break
                  case 'UEAV_notification':
                    this.requests_by_sector_counts.ueav += 1
                    break

                  case 'UEGT_carte_professionnelle':
                    this.requests_by_sector_counts.uegt += 1
                    break
                  case 'UEGT_renew_carte_professionnelle':
                    this.requests_by_sector_counts.uegt += 1
                    break
                  case 'UEGT_changement_affectation':
                    this.requests_by_sector_counts.uegt += 1
                    break
                  case 'UEGT_ajout_specialite':
                    this.requests_by_sector_counts.uegt += 1
                    break

                  case 'UER_autorisation':
                    this.requests_by_sector_counts.uer += 1
                    break
                  case 'UER_classement':
                    this.requests_by_sector_counts.uer += 1
                    break
                  case 'UER_renew_autorisation':
                    this.requests_by_sector_counts.uer += 1
                    break
                  case 'UER_renew_classement':
                    this.requests_by_sector_counts.uer += 1
                    break
                  case 'UER_notification':
                    this.requests_by_sector_counts.uer += 1
                    break
                  default:
                    break
                }

                if (this.requests_by_sector_counts.ueh > 0)
                  this.getListTypeHebergement()

                // console.log('1 ::: 💊',)

                demande.have_autorisation_visit =
                  selectedForm.have_autorisation_visit
                demande.have_classification_visit =
                  selectedForm.have_classification_visit
                demande.have_exam_visit = selectedForm.have_exam_visit

                demande.have_metting_with_applicant =
                  selectedForm.have_metting_with_applicant
                demande.have_morality_investigation =
                  selectedForm.have_morality_investigation

                demande.step_before_ot_decisions =
                  selectedForm.step_before_ot_decisions
                demande.step_before_minister_decisions =
                  selectedForm.step_before_minister_decisions
                demande.step_before_ot_decisions_classification =
                  selectedForm.step_before_ot_decisions_classification
                demande.step_before_minister_decisions_classification =
                  selectedForm.step_before_minister_decisions_classification

                demande.process_steps = selectedForm.process_steps

                // console.log('2 ::: 💊 demande.process_steps', demande.process_steps)
                // console.log('2 ::: 💊 demande.statuses[0].name', demande.statuses[0].name)

                let curr_step = null
                if (filteredArray.length > 0) {
                  curr_step = this.get_current_step(
                    demande.process_steps,
                    demande.statuses[0].name,
                    'reverse'
                  )
                  // console.log('curr_step::: ', curr_step)
                } else {
                  curr_step = this.get_current_step(
                    demande.process_steps,
                    demande.statuses[0].name
                  )
                }
                if (curr_step) demande.curr_step = curr_step

                // console.log('3 ::: 💊 curr_step', curr_step)

                // get next steps swith position
                if (curr_step && curr_step.go_to) {
                  const response_good_status = this.get_specific_nodes(
                    demande.statuses,
                    'name',
                    curr_step.go_to,
                    curr_step.offset
                  )
                  const response_good_step = this.get_current_step(
                    demande.process_steps,
                    response_good_status.name
                  )

                  const next_node = this.get_next_nodes(
                    demande.process_steps,
                    response_good_step
                  )
                  if (next_node) demande.next_node = next_node
                } else {
                  const next_node = this.get_next_nodes(
                    demande.process_steps,
                    curr_step
                  )
                  if (next_node) demande.next_node = next_node
                }
                // console.log('demande.next_node::: 💊', demande.next_node)
              }
            })
            this.load_current_state()
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoading = false
        })
    },
    formattedDate(data) {
      const start = new Date(data[0].date)
      const end = new Date(data[data.length - 1].date)

      // Obtenir l'année, le mois et le jour sous forme de chaînes de caractères
      const startYear = start.getFullYear()
      const startMonth = (start.getMonth() + 1).toString().padStart(2, '0') // Ajout d'un 0 devant les mois inférieurs à 10
      const startDay = start.getDate().toString().padStart(2, '0')
      const endYear = end.getFullYear()
      const endMonth = (end.getMonth() + 1).toString().padStart(2, '0')
      const endDay = end.getDate().toString().padStart(2, '0')

      // Construire la chaîne de caractères formatée
      return `L'an deux mil vingt-${endYear}, et du ${startDay} ${startMonth} au ${endDay} ${endMonth}, rend moi ${this.startDate} au ${this.endDate} sous le format ci-dessus`
    },
    pv_data_regroup(demandes) {
      this.listVisits = []
      this.requestFavorables = []
      this.requestFavorablesWithConditions = []
      this.requestUnFavorables = []
      this.totalRequests = demandes.length
      let visite = {}
      demandes.forEach((demande) => {
        demande.visits.forEach((visit) => {
          this.totalVisiteCounts += 1
          this.totalAgents += visit.visas.length
          if (
            this.curr_arrete.status === 'deliberation_session_check' &&
            visit.type === 'technical'
          ) {
            visite = {
              ...visit,
              departement: demande.user.profile.departement,
              commune: demande.user.profile.commune,
              arrondissement: demande.user.profile.arrondissement,
              quartier: demande.user.profile.quartier,
              code_demande: demande.data.code_demande,
            }
          }

          if (
            this.curr_arrete.status ===
              'classification_deliberation_session_check' &&
            visit.type === 'classment'
          ) {
            visite = {
              ...visit,
              departement: demande.user.profile.departement,
              commune: demande.user.profile.commune,
              arrondissement: demande.user.profile.arrondissement,
              quartier: demande.user.profile.quartier,
              code_demande: demande.data.code_demande,
            }
          }

          this.listVisits.push(visite)
        })
        // this.formatPhrase(this.listVisits)
        const status = demande.statuses[0].name
        switch (status) {
          case 'unfavourable_agreement':
            this.requestUnFavorables.push(demande)
            break
          case 'favourable_agreement_with_conditions':
            this.requestFavorablesWithConditions.push(demande)
            break
          case 'favourable_agreement':
            this.requestFavorables.push(demande)
            break

          default:
            break
        }
      })

      // console.log(
      //   'pv datas=-=--=-=-=-=-=-=-=-=-=-=::: ',
      //   this.totalRequests,
      //   this.totalVisiteCounts,
      //   this.totalAgents,
      //   this.listVisits,
      //   this.requestUnFavorables,
      //   this.requestFavorablesWithConditions,
      //   this.requestFavorables
      // )
    },
    getListTypeHebergement() {
      this.action_searchTypeEtablissement()
        .then((res) => {
          this.options_type_secteur_hebergement = res.data.data || []
        })
        .catch((err) => {})
    },
    show_decisions_buttons(status) {
      if (status === 'deliberation_session_check') return true
      return false
    },
    load_ampliation() {
      this.ampliation_attributes = Object.entries(
        this.curr_arrete.arrete_data.ampliations
      )
    },
    add_ampliation() {
      if (
        this.new_ampliation_attributes.structure == null ||
        this.new_ampliation_attributes.copies == null
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Veuillez préciser la structure et le nombre de copies.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      this.curr_arrete.arrete_data.ampliations[
        this.new_ampliation_attributes.structure
      ] = this.new_ampliation_attributes.copies
      ;(this.new_ampliation_attributes = {
        structure: null,
        copies: null,
      }),
        this.load_ampliation()
      this.update_arrete('arrete_data')
    },
    delete_ampliation(attribut) {
      delete this.curr_arrete.arrete_data.ampliations[attribut]
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Ampliation ${attribut} supprimée`,
          icon: 'CheckIcon',
          variatnt: 'success',
        },
      })
      this.load_ampliation()
      this.update_arrete('arrete_data')
    },
    download_arrete() {
      this.isLoadingDownloadArrete = true
      this.arretes_links = []
      // console.log('getListDemandes params::: 🟢🔴🟢', )
      this.actions_getArreteDownloadArrete(this.current_id)
        .then(async (response) => {
          console.log('download_arrete response::: 🟢🔴🟢', response);
          this.isLoadingDownloadArrete = false
          const { data } = response
          const sectors = Object.entries(data)
          // console.log('sectors::: ', sectors, sectors.length);
          for (let index = 0; index < sectors.length; index++) {
            const secteur = sectors[index]
            const requests = Object.entries(secteur[1])
            for (let index = 0; index < requests.length; index++) {
              const request = requests[index]
              const url = request[1]
              this.arretes_links.push({ request: request[0], url: request[1] })
              // await this.donwload_file_by_url(url)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingDownloadArrete = false
        })
    },
    load_current_state() {
      this.loading_steps_panel = true
      const status_code = this.curr_arrete && this.curr_arrete.statuses[0].name
      for (let index = 0; index < this.states.length; index++) {
        const state = this.states[index]
        if (state.status === status_code) {
          this.current_state = state
          if (this.current_state.final_state) {
            this.next_state = null
            this.curr_decisions = []
            setTimeout(() => {
              this.loading_steps_panel = false
            }, 1000)
          } else {
            this.get_next_nodes_local()
          }
          break
        }
      }
    },
    get_next_nodes_local() {
      if (this.current_state.next_decisions_options_count) {
        // next is decisions nodes
        this.next_state = null
        // function ---------------------------------------------
        this.curr_decisions = []
        const { next_decisions_options_count } = this.current_state
        const index = this.states.findIndex(
          (object) => object.status === this.current_state.status
        )
        // load decisions
        let next_index = index
        const decisions = []
        for (let index = 0; index < next_decisions_options_count; index++) {
          next_index += 1
          decisions.push(this.states[next_index])
        }
        this.curr_decisions = decisions
        setTimeout(() => {
          this.loading_steps_panel = false
        }, 1000)
      } else {
        // next is'nt decisions nodes
        this.curr_decisions = []
        const step = this.states.filter(
          (obj) => obj.status === this.current_state.next_step
        )
        this.next_state = step[0]
        this.loading_steps_panel = false
        // setTimeout(() => {
        // }, 1000);
      }
    },
    request_have_ot_decisions(request, all) {
      const cur_step = request.curr_step
      const validNames = [
        'unfavourable_agreement',
        'favourable_agreement_with_conditions',
        'favourable_agreement',
        'reclassification_unfavourable_agreement',
        'reclassification_favourable_agreement',

        'classification_unfavourable_agreement',
        'classification_favourable_agreement',
      ]
      const validNames_classic = [
        'unfavourable_agreement',
        'favourable_agreement_with_conditions',
        'favourable_agreement',
        'reclassification_unfavourable_agreement',
        'reclassification_favourable_agreement',
      ]
      const validNames_classification = [
        'classification_unfavourable_agreement',
        'classification_favourable_agreement',
      ]
      const request_status = request.statuses.map((status) => status.name)
      if (all) {
        const response = validNames.some((element) =>
          request_status.includes(element)
        )
        // const foundElement = validNames.find(element => request_status.includes(element));
        return response
      }

      if (cur_step && cur_step.audit_step) {
        // audit steps
        return validNames_classification.some((element) =>
          request_status.includes(element)
        )
      }
      // current treatment steps
      return validNames_classic.some((element) =>
        request_status.includes(element)
      )
    },
    request_have_minister_decisions(request, all) {
      const cur_step = request.curr_step
      const validNames = [
        'minister_reject_classsification',
        'minister_accept_classsification',
        'minister_unfavourable_agreement',
        'minister_favourable_agreement_with_conditions',
        'minister_favourable_agreement',
      ]
      const validNames_classic = [
        'minister_unfavourable_agreement',
        'minister_favourable_agreement_with_conditions',
        'minister_favourable_agreement',
      ]
      const validNames_classification = [
        'minister_reject_classsification',
        'minister_accept_classsification',
      ]
      const request_status = request.statuses.map((status) => status.name)
      if (all) {
        return validNames.some((element) => request_status.includes(element))
      }
      if (cur_step.audit_step) {
        // audit steps
        return validNames_classification.some((element) =>
          request_status.includes(element)
        )
      }
      // current treatment steps
      return validNames_classic.some((element) =>
        request_status.includes(element)
      )
    },
    update_arrete(attribut) {
      this.isLoading_update_state = true
      const params = {}
      params[attribut] = this.curr_arrete[attribut]
      console.log('update_arrete params::: ⭕️⭕️', params, attribut)
      if(attribut == 'arrete_data'){
        params.arrete_data.date = this.replaceSlashByDash(params.arrete_data.date)
      }
      // return;
      console.log('⭕️⭕️⭕️::: 1', );
      this.actions_updateArrete({
        id: this.curr_arrete.id,
        data: params,
      })
        .then((res) => {
          console.log('res::: ⭕️⭕️', res);
          this.isLoading_update_state = false
          if (res.status === 202) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification enregistrée avec succès',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoading_update_state = false
        })
    },
    update_status_arrete(final_action) {
      const allValid_ot = this.all_requests_have_ot_decisions
      if (!allValid_ot) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Veuillez préciser pour chaque demande la décision de l'organe technique.",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      if (
        this.can_edit_arrete &&
        (!this.curr_arrete.numero || this.curr_arrete.numero === '')
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Vous devez saisir le numéro de l'arrêté pour continuer.",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      if (
        this.curr_arrete.statuses[0].name !== 'deliberation_session_check' &&
        this.curr_arrete.statuses[0].name !==
          'classification_deliberation_session_check'
      ) {
        const allValid_minister = this.all_requests_have_minister_decisions
        if (!allValid_minister) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                'Veuillez préciser pour chaque demande la décision du ministre.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return
        }
      }

      this.isLoading_update_state = true
      let data = {}
      if (!final_action) {
        data = {
          status: this.next_state.status,
          commentaire: this.next_step_comment,
        }
      } else {
        data = {
          status: final_action,
          commentaire: this.next_step_comment,
        }
      }
      if (this.next_state.no_sync_with_requests)
        data.no_sync_with_requests = true
      if (this.next_state.send_mail) data = { ...data, send_mail: true }

      let final_data = {}
      if (data.status == 'minister_decisions') {
        // control on minoster decision
        final_data = {
          status: data.status,
          no_sync_with_requests: true,
        }
      } else {
        // classic process
        final_data = {
          status: data.status,
          commentaire: data.commentaire,
          demandes: [],
          no_sync_with_requests: true,
        }

        this.curr_arrete.demandes.forEach((demande) => {
          if (
            data.status == 'transfert_pv_deliberation_to_minister' &&
            demande.curr_step.audit_step
          ) {
            final_data.demandes.push({
              demande_id: demande.id,
              next_status: 'transfert_all_documentation_to_minister',
            })
            // if(demande.curr_step.audit_step) {
            // } else {
            //   final_data.demandes.push({
            //     demande_id : demande.id,
            //     next_status : data.status
            //   })
            // }
          }
          if (data.status == 'user_notification') {
            final_data.demandes.push({
              demande_id: demande.id,
              next_status: demande.next_node.status,
            })
          }
          final_data.demandes.push({
            demande_id: demande.id,
            next_status: data.status,
          })
        })
      }

      // console.log('update_status_arrete final_data::: ⭕️', final_data);
      // return
      console.log('⭕️⭕️⭕️::: 2', );
      this.actions_updateArrete({
        id: this.current_id,
        data: final_data,
      })
        .then((result) => {
          this.isLoading_update_state = false
          if (result.status === 202) {
            this.next_step_comment = ''
            // this.curr_arrete.statuses = result.data.data.statuses
            this.load_requests()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification enregistrée',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err ::: ', err)
          this.isLoading_update_state = false
        })
    },
    update_status_request(node, request_id, request_position) {
      const { status } = node
      if (
        this.can_edit_arrete &&
        (!this.curr_arrete.numero || this.curr_arrete.numero === '')
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Vous devez saisir le numéro de l'arrêté pour continuer.",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.isLoading_update_state = true
      let data = {}
      if (!status) {
        data = {
          status: this.next_state.status,
          commentaire: this.next_step_comment_request[request_position],
        }
      } else {
        data = {
          status,
          commentaire: this.next_step_comment_request[request_position],
        }
      }
      if (node.send_mail) data = { ...data, send_mail: true }
      // return
      this.action_updateFormulaire({
        id: request_id,
        data,
      })
        .then((result) => {
          this.isLoading_update_state = false
          if (result.status === 200) {
            this.next_step_comment_request[request_position] = ''
            // this.curr_arrete.demandes[request_id].statuses = result.data.data.statuses
            this.load_requests()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification enregistrée',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err update_status_request::: ', err)
          this.isLoading_update_state = false
        })
    },
    update_request(request_id, request_position, data) {
      // console.log('update_request +++++ request_id, request_position::: ', request_id, request_position);
      // console.log('data::: ', data);
      // return

      this.action_updateFormulaire({
        id: request_id,
        data,
      })
        .then((result) => {
          this.isLoading_update_state = false
          if (result.status === 200) {
            this.next_step_comment_request[request_position] = ''
            // this.curr_arrete.demandes[request_id].statuses = result.data.data.statuses
            // this.load_requests()
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  'Modification de la décision de la commission enregistrée',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch((err) => {
          console.log('err update_status_request::: ', err)
          this.isLoading_update_state = false
        })
    },

    getTypeDemandebyCode(code) {
      const selectedForm = utilsService.getFormulaireByCodeDemande(code)
      return selectedForm.title
    },
    remove_request(request) {
      const filtred_list = this.curr_arrete.demandes.filter(
        (req) => req.id != request.id
      )
      this.curr_arrete.demandes = filtred_list
    },
    onPaginationChange(val) {
      this.paginationData = { ...this.paginationData, ...val }
    },
    variant_for_timeline(curr_status_name) {
      for (let index = 0; index < this.states.length; index++) {
        if (this.states[index].status === curr_status_name) {
          return this.states[index].class.split('-')[1]
        }
      }
    },
    get_request_color(demande, base_status) {
      const cur_sep = this.get_current_step(
        demande.process_steps,
        this.get_specific_nodes(demande.statuses, 'name', base_status, 1).name
      )
      return cur_sep.class.split('-')[1]
    },
    get_user_by_id(id) {
      for (
        let index = 0;
        index < this.adminstration_user_list.length;
        index++
      ) {
        const user = this.adminstration_user_list[index]
        if (user.id === id) {
          user.fullname = `${user.lastname} ${user.firstname}`
          return user
        }
      }
    },
    curr_step_before_ot_decisions(request_index) {
      const cur_step = this.curr_arrete.demandes[request_index].curr_step
      if (cur_step.audit_step) {
        // audit steps
        return this.curr_arrete.demandes[request_index]
          .step_before_ot_decisions_classification
      }
      // current treatment steps
      return this.curr_arrete.demandes[request_index].step_before_ot_decisions
    },
    curr_step_before_minister_decisions(request_index) {
      const cur_step = this.curr_arrete.demandes[request_index].curr_step
      if (cur_step.audit_step) {
        // audit steps
        return this.curr_arrete.demandes[request_index]
          .step_before_minister_decisions_classification
      }
      // current treatment steps
      return this.curr_arrete.demandes[request_index]
        .step_before_minister_decisions
    },
    in_audit_process(request_index) {
      const cur_step = this.curr_arrete.demandes[request_index].curr_step
      if (cur_step) return cur_step.audit_step
      // const request_status = this.curr_arrete.demandes[request_index].statuses.map(status => status.name)
      // const filteredArray = request_status.filter(item => item === 'minister_decisions');
      // return filteredArray.length >= 2;
    },
    get_resquest_index(id) {
      for (let index = 0; index < this.curr_arrete.demandes.length; index++) {
        const request = this.curr_arrete.demandes[index]
        if (request.id == id) return index
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '@core/scss/base/pages/app-invoice.scss';

.horizontal-scroll {
  display: inline-block;
  overflow-x: auto;
  white-space: nowrap;
}

.main-grid-file-display {
  display: grid;
  grid-template-columns: auto 800px;
  column-gap: 30px;
  position: relative;

  .options-sidebar {
    overflow: auto;
    height: 90vh;
    padding-bottom: 59px;
    position: fixed;
    right: 1.5rem;
    width: 800px;
  }
}

.main-grid {
  display: grid;
  grid-template-columns: auto 350px;
  column-gap: 30px;
  position: relative;

  .options-sidebar {
    overflow: auto;
    height: 90vh;
    padding-bottom: 59px;
    position: fixed;
    right: 1.5rem;
    width: 350px;
  }
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .main-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 30px;
    position: relative;

    .options-sidebar {
      overflow: auto;
      height: unset;
      position: unset;
      width: unset;
    }
  }
}

.d-content {
  display: contents;
}

#sidebar-wrapper {
  position: fixed;
  right: 250px;
  width: 100%;
  height: 100%;
  margin-right: -90%;
  overflow-y: auto;

  .sidecard {
    margin: 0 0 800px 0;
  }
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}

@import '@core/scss/vue/libs/vue-flatpicker.scss';

.pdf-item {
  page-break-inside: avoid;
}

.demandes {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  box-shadow: 1px 1px 3px #dee2e6;
  padding: 10px;
}

.link {
  cursor: pointer;
}

.link:hover {
  color: #2e7a6f;
}

.intro-page {
  background-color: rgb(201, 225, 250);
  padding: 20px;
  border: 1px solid rgb(169, 211, 255);
  margin: 180px 0;
  border-radius: 20px;
}
.head_bg {
  background-color: #b8cce4 !important;
}
.name-cell {
  display: flex; /* Enable flexbox for layout */
}

.name-cell .name {
  padding: 0 5px; /* Add padding between names */
  border-right: 1px solid #ccc; /* Add border after each name */
  &:last-child {
    /* Remove border for the last name */
    border-right: none;
  }
}
</style>
